import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";


export default function ThankYouContacting() {
    const nav = useNavigate()
    useEffect(() => {
        setTimeout(() => nav('/'), 5000)
    })


    return (
        <>
            <div className="thank-contact">
                <h2 className="thank-contact-msg">Thank You !</h2>
                <div className="thank-contact-text">
                    <p>Thanks for contacting us.</p>
                    <p>We will get back to you shortly.</p>
                </div>

            </div>
            {/* <img
                src={
                    require(`../../assets/images/thanku/thank-you-banner.png`)
                        .default
                }
                alt="Service"
            /> */}
        </>
    );
}
