import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logos/logo-250.png";
import "./style.css";

function Drawer({ drawer, action }) {
  const [itemSize, setSize] = useState("0px");
  const [item, setItem] = useState("");

  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (getItems > 0) {
      if (item !== value) {
        setSize(`${50 * getItems}px`);
        setItem(value);
      } else {
        setItem("");
      }
    }
  };

  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${drawer ? "active" : ""}`}
      ></div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}
              >
                <Link
                  to="/"
                  className="offcanvas-brand text-center mb-40 d-block"
                >
                  <img src={logo} alt="" style={{ width: "150px" }} />
                </Link>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    <li
                      onClick={(e) => handler(e, "home")}
                      id="home"
                      className="menu-item-has-children active"
                    >
                      <Link to="/">Home</Link>
                    </li>
                    <li
                      onClick={(e) => handler(e, "about")}
                      id="about"
                      className="menu-item-has-children active"
                    >
                      <Link to="/about">About</Link>
                    </li>
                    <li
                      onClick={(e) => handler(e, "service")}
                      id="service"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <Link to="#">Products</Link>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "service" ? itemSize : "0px",
                        }}
                      >
                        <li
                          onClick={() => {
                            action();
                          }}
                        >
                          <Link to="/multi/0">Multi GNSS Processor</Link>
                        </li>
                        <li
                          onClick={() => {
                            action();
                          }}
                        >
                          <Link to="/module/1">Modules</Link>
                        </li>
                        <li
                          onClick={() => {
                            action();
                          }}
                        >
                          <Link to="/antenna/4">GNSS Antenna</Link>
                        </li>
                        <li
                          onClick={() => {
                            action();
                          }}
                        >
                          <Link to="/receiver/2">GNSS Receivers</Link>
                        </li>
                        <li
                          onClick={() => {
                            action();
                          }}
                        >
                          <Link to="/drone/3">Drone Navigation Unit</Link>
                        </li>
                        <li
                          onClick={() => {
                            action();
                          }}
                        >
                          <Link to="/clock/5">NavIC Clock</Link>
                        </li>
                        <li
                          onClick={() => {
                            action();
                          }}
                        >
                          <Link to="/map/6">Map Display Systems</Link>
                        </li>
                        <li
                          onClick={() => {
                            action();
                          }}
                        >
                          <Link to="/tracking/7">Tracking Devices</Link>
                        </li>
                      </ul>
                    </li>

                    <li
                      onClick={(e) => handler(e, "tracking")}
                      id="tracking"
                      className="menu-item-has-children active"
                    >
                      <Link to="/tracking-page">Tracking Systems</Link>
                    </li>
                    <li
                      onClick={(e) => handler(e, "news")}
                      id="news"
                      className="menu-item-has-children active"
                    >
                      <Link to="/news">News</Link>
                    </li>
                    <li
                      onClick={(e) => handler(e, "faq")}
                      id="faq"
                      className="menu-item-has-children active"
                    >
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li
                      onClick={(e) => handler(e, "events")}
                      id="events"
                      className="menu-item-has-children active"
                    >
                      <Link to="/events">Events</Link>
                    </li>
                    <li
                      onClick={(e) => handler(e, "downloads")}
                      id="downloads"
                      className="menu-item-has-children active"
                    >
                      <Link to="/downloads">Downloads</Link>
                    </li>
                    <li
                      onClick={(e) => handler(e, "downloads")}
                      id="downloads"
                      className="menu-item-has-children active"
                    >
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li
                      onClick={(e) => handler(e, "goto")}
                      id="goto"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <Link to="#">Go To</Link>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "goto" ? itemSize : "0px",
                        }}
                      >
                        <li
                          onClick={() => {
                            action();
                          }}
                        >
                          <Link
                            to="http://13.234.205.213/ivts/#/page/signin"
                            target="_blank"
                          >
                            IVTS
                          </Link>
                        </li>
                        <li
                          onClick={() => {
                            action();
                          }}
                        >
                          <Link
                            to="https://sbms.elenageosys.com/login"
                            target="_blank"
                          >
                            SBMS
                          </Link>
                        </li>
                        <li
                          onClick={() => {
                            action();
                          }}
                        >
                          <Link
                            to="https://eim.elenageosys.com/admin/login/?next=/admin/"
                            target="_blank"
                          >
                            Staff Login
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="offcanvas-social">
                  <ul className="text-center">
                    <li
                      onClick={() => {
                        action();
                      }}
                    >
                      <Link
                        to="https://www.facebook.com/people/Elena-Geo-Systems-Private-Limited/100063509753575/"
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        action();
                      }}
                    >
                      <Link
                        to="https://twitter.com/ElenaGeoSys"
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 512 512"
                        >
                          <path
                            id="time-1-icon"
                            d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                          />
                        </svg>
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        action();
                      }}
                    >
                      <Link
                        to="https://instagram.com/elena_geosystems?igshid=MzRlODBiNWFlZA=="
                        target="_blank"
                      >
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        action();
                      }}
                    >
                      <Link
                        to="https://www.linkedin.com/company/elena-geo-systems-private-limited/"
                        target="_blank"
                      >
                        <i className="fab flaticon-linkedin-2"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-widget-info">
                  <ul>
                    <li>
                      <Link to="mailto:sales@elenageosystems.com">
                        <i className="fa fa-envelope"></i>{" "}
                        sales@elenageosystems.com
                      </Link>
                    </li>
                    <li>
                      <Link to="callto:+919384864411">
                        <i className="fa fa-phone"></i> +919384864411
                      </Link>
                    </li>
                    <li>
                      <Link to="https://maps.app.goo.gl/oUQMkystQmQU6ys5A">
                        <i className="fa fa-map-marker-alt"></i> Bangalore
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Drawer;
