import React, { createContext, useContext, useState } from 'react';

const EventCardDataContext = createContext();

export const useEventCardDataContext = () => useContext(EventCardDataContext);

export const EventCardData = ({ children }) => {
  const [EventData, setEventData] = useState(null);
    
  return (
    <EventCardDataContext.Provider value={{ EventData, setEventData }}>
      {children}
    </EventCardDataContext.Provider>
  );
};
