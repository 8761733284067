import React from "react";
import pdffile3 from '../../assets/downloadpdf/ElenaArmoredNavigator.pdf';
import pdffile5 from '../../assets/downloadpdf/ElenaHHNN.pdf';
import pdffile8 from '../../assets/downloadpdf/ElenaIVTS.pdf';
import pdffile4 from '../../assets/downloadpdf/ElenaNDNUv3.pdf';
import pdffile6 from '../../assets/downloadpdf/ElenaNNMA.pdf';
import pdffile7 from '../../assets/downloadpdf/ElenaPrecisionSurveyDevice.pdf';
import img1 from '../../assets/images/download-images/armoured-vehicale.png';
import img4 from '../../assets/images/download-images/marine-application.png';
import img6 from '../../assets/images/download-images/vehicale-tracking.png';
import img2 from '../../assets/images/download-images/navic-drone.png';
import img3 from '../../assets/images/download-images/handled-navigator.png';



export default function Downloads() {
    return (
        <>
            <section className="pricing-section-two pt-50 rpt-95 pb-50 rpb-50  download-bgimg ">
                <div className="container">
                    <div className="section-title text-center mb-50">
                        {/* <span className="sub-title">Pricing Packages</span> */}
                        {/* <h2 className="text-white">Amazing Works</h2> */}
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-sm-6">
                            <div className="pricing-item style-two wow fadeInUp delay-0-2s">
                                <img src={img1} alt="" width={180} height={150}></img>
                                <div className="pricing-header">
                                    <h6 className="pricing-title">Navic Navigator For Armoured Vehicles</h6>
                                </div>

                                <a href={pdffile3} target="_blank"
                                    rel="noreferrer" className="theme-btn btn-circle" >
                                    Download
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="pricing-item style-two wow fadeInUp delay-0-2s">
                                <img src={img2} alt="" width={180} height={150}></img>
                                <div className="pricing-header">
                                    <h6 className="pricing-title">NavIC Drone Navigation Unit (NDNU)</h6>
                                </div>

                                <a href={pdffile4} target="_blank"
                                    rel="noreferrer" className="theme-btn btn-circle" >
                                    Download
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="pricing-item style-two wow fadeInUp delay-0-2s">
                                <img src={img3} alt="" width={180} height={150}></img>
                                <div className="pricing-header">
                                    <h6 className="pricing-title">HandHeld NavIC Navigator (HHNN)</h6>
                                </div>

                                <a href={pdffile5} target="_blank"
                                    rel="noreferrer" className="theme-btn btn-circle" >
                                    Download
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="pricing-item style-two wow fadeInUp delay-0-2s">
                                <img src={img4} alt="" ></img>
                                <div className="pricing-header ">
                                    <h6 className="pricing-title ">NavIC Navigator For Marine Application</h6>
                                </div>

                                <a href={pdffile6} target="_blank"
                                    rel="noreferrer" className="theme-btn btn-circle" >
                                    Download
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="pricing-item style-two wow fadeInUp delay-0-2s">
                                <img src={img3} alt="" width={180} height={150}></img>
                                <div className="pricing-header">
                                    <h6 className="pricing-title">Precision Survey Device powered by NavIC</h6>
                                </div>
                                <a href={pdffile7} target="_blank"
                                    rel="noreferrer" className="theme-btn btn-circle" >
                                    Download
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="pricing-item style-two wow fadeInUp delay-0-2s">
                                <img src={img6} alt=""  width={180} height={150}></img>
                                <div className="pricing-header">
                                    <h6 className="pricing-title">Intelligence Vehicle Tracking System (IVTS)</h6>
                                </div>

                                <a href={pdffile8} target="_blank"
                                    rel="noreferrer" className="theme-btn btn-circle" >
                                    Download
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
