import { useEffect } from "react";
import { news } from "../../data/news.json";
import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeOne from "../partials/Footers/FooterHomeOne";
import HeaderHomeOne from "../partials/Headers/HeaderHomeOne/index";
import AboutSection from "./AboutSection";
import FeatureSection from "./FeatureSection";
// import GallerySection from "./GallerySection";
import Hero from "./Hero";
import NewsSection from "./NewsSection";
import { useEventCardDataContext } from "../Context/EventContext";
// import PricingSection from "./PricingSection";
// import ServiceSection from "./ServiceSection";
// import WorkProcessSection from "./WorkProcessSection";

// import HeroEight from './HeroEight';

function HomeOne() {
  const { setEventData } = useEventCardDataContext();
  const EventURL = "https://enquiry.elenageosys.com/event/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(EventURL);

        if (!response.ok) {
          throw new Error("Error fetching the Event data");
        }
        const result = await response.json();
        setEventData(result);
      } catch (error) {
        console.log("Error on fetching the event API", error);
      }
    };

    fetchData();
  }, [EventURL, setEventData]);
  return (
    <div style={{ overflowX: "hidden" }}>
      <HeaderHomeOne />
      <Hero />
      {/* <HeroEight /> */}
      <FeatureSection />
      {/* <ServiceSection /> */}
      <AboutSection />
      {/* <WorkProcessSection /> */}
      {/* <PricingSection /> */}
      <NewsSection news={news} />
      {/* <GallerySection /> */}
      <FooterHomeOne />
      <BacktoTopCom />
    </div>
  );
}

export default HomeOne;
