import React from "react";
import ThankYouContacting from "./ThankYouContacting";

export default function Thankyou() {
    return (
        <>
            <ThankYouContacting />
        </>
    );
}
