import React from "react";
// import TestimonialSection from "../../HomeThree/TestimonialSection";
// import newsBg from "../../assets/images/news/news-bg.jpg";
import Layouts from "../helpers/Layouts";

// import { blogs } from "../../data/blogs.json";
import { news } from "../../data/news.json";
import NewsSection from "./NewSections";

export default function News() {
  return (
    <>
      <Layouts >
        <NewsSection news={news} />
      </Layouts>
    </>
  );
}
