import React from "react";
import { Link } from "react-router-dom";

function ColumCardStyleOne({ datas }) {
  const calculateDate = (dateString) => {
    if (!dateString || typeof dateString !== "string") {
      return null;
    }

    const [year, month, day] = dateString.split("-");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const formattedMonth = monthNames[parseInt(month, 10) - 1]; // Adjust for 0-based array index

    return `${parseInt(day, 10)} ${formattedMonth} ${year}`;
  };

  return (
    <div className="news-item events-item wow fadeInUp delay-0-2s">
      <div className="image text-center ">
        <img
          src={datas.image}
          alt="Events"
          style={{ height: "250px", width: "370px" }}
        />
      </div>
      <div className="events-content">
        <ul className="post-meta-item">
          <li>
            <i className="fas fa-tag"></i>
            <a className="events-head-tag">{datas.event_tag}</a>
          </li>
          <li>
            <div className="d-flex">
              {/* 22 December 2021 */}
              <div className="d-flex align-items-center">
                <i className="fa fa-map-marker-alt"></i>
                <span className="event-venue">{datas.event_venue}</span>
              </div>
            </div>
          </li>
          <li>
            <i className="fas fa-calendar-alt"></i>
            <span>{calculateDate(datas.date_of_event)}</span>
          </li>
          <li>
            <span className="line-clamp-2">{datas.event_content}</span>
          </li>
        </ul>

        <div className="event-download-txt d-flex">
          <div className="event-download-btn">
            <Link
              to={datas.event_pdf}
              target="_blank"
              rel="noreferrer"
              download
            >
              Download
              <i className="fas fa-long-arrow-alt-down"></i>
            </Link>
          </div>
          <div className="event-download-txt ">
            <Link
              to={datas.event_link}
              target="_blank"
              rel="noreferrer"
              download
            >
              Visit website ...
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColumCardStyleOne;
