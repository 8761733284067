import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SliderCom from "../../helpers/SliderCom";
import ShopSidebar from "../Shop/ShopSidebar";
// import ElenaE1AChip from "../../../assets/downloadpdf/Elena-E1A-Chip.pdf";

export default function MainSection({ product }) {

  const bigSlider = {
    dots: false,
    infinite: true,
    autoplay: false,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const smallSlider = {
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: false,
    speed: 1000,
    focusOnSelect: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  useEffect(() => {
    if (nav1) {
      nav1.slickGoTo(0);
    }
  }, [product, nav1])
  // const [quantity, setQuantity] = useState(0);
  // const inputNumber = (e) => {
  //   setQuantity(e.target.value);
  // };
  // const [tab, setTab] = useState("description");
  // const tabHandler = (e, value) => {
  //   e.preventDefault();
  //   setTab(value);
  // };

  return (
    <section className="shop-details-area pt-80 py-30 rpy-100">
      {/* {data} */}
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="shop-details-content">
              <div className="row">
                <div className="col-md-6">
                  <div className="product-gallery">
                    <SliderCom
                      asNavFor={nav2}
                      selector={(slider1) => setNav1(slider1)}
                      settings={bigSlider}
                    >

                      {product.prev_picture.map((prevImg, index) => (
                        <a className="product-image-preview" key={index}>
                          <div className="prod-img-prev">
                            <img
                              className="img-fluid"
                              src={
                                require(`../../../assets/images/product/prod-img/${prevImg}`)
                                  .default
                              }
                              style={{ width: "280px" }}
                              alt="Preview"
                            />
                          </div>
                        </a>
                      ))}

                    </SliderCom>
                  </div>
                  <div className="product-thumb py-10 mb-40">
                    <SliderCom
                      asNavFor={nav1}
                      selector={(slider1) => setNav2(slider1)}
                      settings={smallSlider}
                    >
                      {product.thumb_picture.map((thumbImg, index) => (
                        <a className="product-thumb-item" key={index}>
                          <img
                            src={
                              require(`../../../assets/images/product/prod-img/${thumbImg}`)
                                .default
                            }

                            alt="Thumb"
                          />
                        </a>
                      ))}
                    </SliderCom>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="descriptions mb-30">
                    <h2>{product.name}</h2>
                    <p>{product.shortDescription}</p>
                  </div>
                </div>
              </div>
              {/* <ul className="nav product-information-tab mb-30">
                <li className="mb-5">
                  <a
                    onClick={(e) => tabHandler(e, "description")}
                    href="#"
                    className={tab === "description" ? "active show" : ""}
                  >
                    Description
                  </a>
                </li>
                <li>
                  <a
                    onClick={(e) => tabHandler(e, "review")}
                    href="#"
                    className={tab === "review" ? "active show" : ""}
                  >
                    Technical Specifications
                  </a>
                </li>
              </ul> */}
              <div className="tab-content mb-0 text-justify">
                {/* <div
                  className={`tab-pane fade ${
                    tab === "description" ? "active show " : ""
                  }`}
                  id="details"
                > */}
                <h2>Description</h2>
                <p className="">{product.description}</p>
                {/* </div> */}
                {/* <div
                  className={`tab-pane fade ${
                    tab === "review" ? "active show " : ""
                  }`}
                  id="review"
                >
                  <div className="pt-0">
                    <ul>
                      {product.techSpecs.map((item, i) => (
                        <li key={i}>{`${i + 1}. ${item}`}</li>
                      ))}
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>

            {/* download */}
            <div className="download-brochure mb-30 d-flex">

              <div className="download-txt " >
                {
                  product.pdf && <Link
                    to={
                      require(`../../../assets/downloadpdf/${product.pdf}`)
                        .default
                    }
                    target="_blank"
                    rel="noreferrer"
                    
                  >
                    View Brochure
                    <i className="fas fa-eye"></i>

                  </Link>
                }
              </div>
              <div className="download-txt ml-20" >
                {
                  product.pdf && <Link
                    to={
                      require(`../../../assets/downloadpdf/${product.pdf}`)
                        .default
                    }
                    target="_blank"
                    rel="noreferrer"
                    download
                  >
                    Download Brochure
                    <i className="fas fa-long-arrow-alt-down"></i>

                  </Link>
                }
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-9">
            <ShopSidebar />
          </div>
        </div>
      </div>


    </section>
  );
}
