import React from "react";
import { Link } from "react-router-dom";
import hero4 from "../../assets/images/hero/hero-six-ellipse.png";
import shape5 from "../../assets/images/shapes/circle.png";
import shape3 from "../../assets/images/shapes/close.png";
import shape1 from "../../assets/images/shapes/lines.png";
import shape4 from "../../assets/images/shapes/triangle.png";
import shape2 from "../../assets/images/shapes/w-shape.png";
import hero3 from "../../assets/images/tracking/car.png";
import hero2 from "../../assets/images/tracking/school-bus-tracking.png";
import hero1 from "../../assets/images/tracking/vehicle-tracking.png";
import useToggle from "../../hooks/useToggle";
import VideoPopupCom from "../helpers/VideoPopupCom";

export default function Hero() {
  const [showVideo, handler] = useToggle();
  return (
    <>
      {showVideo && (
        <VideoPopupCom
          videoSrc="//www.youtube.com/embed/-2qbolPqe9s"
          handler={(e) => handler.toggle(e)}
        />
      )}
      <section
        className="hero-section-six bg-lighter z-1 rel"
        style={{ overflow: "hidden" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="hero-content-six mr-10 rmr-0  rpy-30">
                <span className="sub-title d-block wow fadeInUp delay-0-2s">
                  EMPOWER YOUR BUSINESS
                </span>
                <h1 className="wow fadeInUp delay-0-4s mt-20">
                 Elena's State of art Tracking Technology
                </h1>
                <p className="wow fadeInUp delay-0-6s">
                  Enable accurate and continuous location tracking of vehicles,
                  facilitating real-time mapping, route optimization, and
                  geofencing.
                </p>
                <div className="hero-btns mt-35 wow fadeInUp delay-0-8s">
                  <Link to="/contact" className="theme-btn mr-25 mb-10">
                    Request Demo <i className="fas fa-arrow-right"></i>
                  </Link>
                  <Link
                    to="#"
                    onClick={(e) => handler.toggle(e)}
                    className="mfp-iframe video-play mb-10"
                  >
                    <i className="fas fa-play"></i> <span>Watch Video</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="hero-six-images wow fadeInRight delay-0-8s">
                <img src={hero1} alt="Hero" style={{ width: "600px" }} />
                <img src={hero2} alt="Hero" />
                <img src={hero3} alt="Hero" />
                <img src={hero4} alt="Ellipse" />
              </div>
            </div>
          </div>
        </div>
        <div className="hero-shapes">
          <img src={shape1} alt="Shape" />
          <img src={shape2} alt="Shape" />
          <img src={shape3} alt="Shape" />
          <img src={shape4} alt="Shape" />
          <img src={shape5} alt="Shape" />
        </div>
      </section>
    </>
  );
}
