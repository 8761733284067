import React from "react";
import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeOne from "../partials/Footers/FooterHomeOne";
import HeaderHomeOne from "../partials/Headers/HeaderHomeOne";
import MainQualityCertification from "./MainQualityCertification";

function QualityCertification() {
  return (
    <>
      <HeaderHomeOne />
      <MainQualityCertification />
      {/* <QualityCertificate /> */}
      {/* <OurCertification /> */}
      <FooterHomeOne />
      <BacktoTopCom />
    </>
  );
}

export default QualityCertification;
