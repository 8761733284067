import React, {useEffect} from "react";
// import TestimonialSection from "../../HomeThree/TestimonialSection";

import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeOne from "../partials/Footers/FooterHomeOne/index";
import HeaderHomeOne from "../partials/Headers/HeaderHomeOne/index";
import Feature from "./Features";
import Hero from "./Hero";

import { applications } from "../../data/applications.json";
import { features } from "../../data/features.json";
import Applications from "./Applications";

export default function TrackingPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <HeaderHomeOne />
      <Hero />
      <div className="mb-50">
        <Applications applications={applications} />
        {features.map((feature) => (
          <Feature feature={feature} />
        ))}
      </div>
      {/* {applications.map((application) => ( */}
      {/* ))} */}
      <FooterHomeOne />
      <BacktoTopCom />
    </>
  );
}
