import React from 'react'
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card';
import pageerror from '../../assets/images/404.png'

function Page404() {
    return (
        <>
            
            <div className='page404bg'>
                <div className='d-flex align-items-center justify-content-center pb-4'>
                <img src={pageerror} alt=''></img>
                </div> 
                <div className=' d-flex align-items-center justify-content-center '>
                    <Card style={{ width: '30rem' }} className='rounded page404-card'>
                        <Card.Body>
                            <Card.Title><h1 className='text-center'>404 </h1></Card.Title>
                            <Card.Title><h4 className='text-center'>Page Not Found </h4></Card.Title>
                            <hr />
                            <Card.Subtitle className="mb-2 text-muted text-center"> The Page you are looking for doesn't exist or an other error occured.Go to</Card.Subtitle>
                            <Card.Text>

                            </Card.Text>
                            <div className='menu-btn text-center'>
                                <Link to="/" className="theme-btn">Go Back Home</Link>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>



        </>
    )
}

export default Page404