import React from "react";

export default function ShopSidebar() {
  return (
    <div className="shop-sidebar  rmt-0 ">
      <div className="widget widget-call-action wow fadeInUp delay-0-2s">
        <div className="call-action-widget">
          <h2>Work Together</h2>
          <p>
            Collaborate seamlessly and achieve more together through effective
            teamwork and shared efforts.
          </p>
          <a className="theme-btn style-five btn-circle" href="/contact">
            Contact Now <i className="fas fa-angle-double-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
