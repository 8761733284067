import React from "react";

import bangalore from '../../assets/images/contact/bangalore.png';
import chennai from '../../assets/images/contact/chennai.png';
import delhi from '../../assets/images/contact/delhi.png';
import pune from '../../assets/images/contact/pune.png';


export default function MainSection() {
  return (
    <section className="what-we-do-two text-center pt-20  pb-90 rpb-70">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-3 col-sm-6">
            <div className="feature-item-two wow fadeInUp delay-0-2s">
              <div className="icon">
                <img src={bangalore} alt="Chennai" className="about-icon" />
              </div>
              <div className="feature-line">
                <span className="animate-bar"></span>
              </div>
              <h4>
                <a>Bangalore</a>
              </h4>
              <p>62/1, 1st cross, 2nd main, Ganganagar, Bangalore. 560032</p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="feature-item-two color-two wow fadeInUp delay-0-4s">
              <div className="icon">
                <img src={chennai} alt="Bangalore" className="about-icon" />
              </div>
              <div className="feature-line">
                <span className="animate-bar delay-1-0s"></span>
              </div>
              <h4>
                <a>Chennai</a>
              </h4>
              <p>#5, Sundarajan St, Abhiramapuram,Chennai 600018</p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="feature-item-two color-three wow fadeInUp delay-0-6s">
              <div className="icon">
                <img src={pune} alt="Pune" className="about-icon" />
              </div>

              <div className="feature-line">
                <span className="animate-bar delay-2-0s"></span>
              </div>
              <h4>
                <a>Pune</a>
              </h4>
              <p>'Aarohini', Opp Deenanath Mangeshkar Hospital, Erandwane, Pune 411004</p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="feature-item-two color-four wow fadeInUp delay-0-8s">
              <div className="icon">
                <img src={delhi} alt="Delhi" className="about-icon" />
              </div>

              <div className="feature-line">
                <span className="animate-bar delay-0-5s"></span>
              </div>
              <h4>
                <a>Delhi</a>
              </h4>
              <p>EC - 340, Maya Enclave, Hari Nagar, <br></br> 110064 </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
