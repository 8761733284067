import axios from "axios";
import React, { useState ,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import background from "../../../assets/images/contact/contact-bg.jpg";

export default function GetInTouchSection() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [from, setFrom] = useState("");
  const [remarks, setRemarks] = useState("");
  const [btnName, setBtnName] = useState("Send Message")
  // const [data, setData] = useState(null);
  // const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  // Redirecting to another page
  const nav = useNavigate()

  // FORM VALIDATION
  useEffect(() => {
    // Define a function for form validation
    function validateForm() {
      const newErrors = {};

      if (!name.trim()) {
        // newErrors.name = "Name is required";
      } else if (!/^[A-Za-z\s]+$/.test(name)) {
        newErrors.name = "Name should not contain numbers";
      }

      if (!email.trim()) {
        // newErrors.email = "Email is required";
      } else if (!/^\S+@\S+\.\S+$/.test(email)) {
        newErrors.email = "Invalid email format";
      }

      if (!phoneNumber.trim()) {
        // newErrors.phoneNumber = "Phone Number is required";
      } else if (!/^[1-9][0-9]{9}$/.test(phoneNumber)) {
        newErrors.phoneNumber = "Invalid phone number format";
      }

      if (!from.trim()) {
        // newErrors.from = "";
      }else if(!from){
        newErrors.from = "Company/From is required";

      }

      if (!remarks.trim()) {
        // newErrors.remarks = "Remarks are required";
      }

      // Update the errors state with the new validation results
      setErrors(newErrors);
    }

    // Call the validation function whenever form fields change
    validateForm();
  }, [name, email, phoneNumber, from, remarks]);



  function handleNameOnChange(e) {
    setName(e.target.value);
    console.log(name);
  }
  function handleEmailOnChange(e) {
    setEmail(e.target.value);
  }

  function handlePhoneNumberOnChange(e) {
    setPhoneNumber(e.target.value);
  }

  function handleFromOnChange(e) {
    setFrom(e.target.value);
  }
  function handleRemarksOnChange(e) {
    setRemarks(e.target.value);
  }





  // POSTING  DATA 

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (Object.keys(errors).length === 0) {
      setBtnName("Sending...")
      const formData = {
        name,
        email,
        mobile: phoneNumber,
        query: from,
        remarks,
      }
      try {
        const res = await axios.post('https://enquiry.elenageosys.com/enquiry/', formData)
        console.log(res)
        nav('/thankyou')
      } catch (error) {
        console.log(error)
      }
    }
  }


  return (
    <section className="contact-four pb-120 pt-10 rpy-100" >
      <div className="container">
        <div className="contact-inner-four box-shadow p-35 rpx-25 br-5 bg-white">
          <div className="row">
            <div className="col-lg-7">
              <div className="contact-section-form px-15 rpx-0 rmb-50 wow fadeInUp delay-0-2s animated">
                <div className="section-title mb-20">
                  <h2>Get In Touch</h2>
                </div>
                <form
                  id="contact-form"
                  className="contact-form"
                  name="contact-form"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="name">Your name</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className={`form-control ${errors.name ? "is-invalid" : ""}`}
                          placeholder="Name"
                          onChange={handleNameOnChange}
                          required
                          value={name}
                        />
                        {errors.name && (
                          <div className="invalid-feedback">{errors.name}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="email">Your email address</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className={`form-control ${errors.email ? "is-invalid" : ""}`}
                          placeholder="Email"
                          required
                          onChange={handleEmailOnChange}
                          value={email}
                        />
                         {errors.email && (
                          <div className="invalid-feedback">{errors.email}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="number">Your phone number</label>
                        <input
                          type="text"
                          id="number"
                          name="number"
                          className={`form-control ${errors.phoneNumber ? "is-invalid" : ""}`}
                          placeholder="Phone Number"
                          required
                          pattern="[1-9]{1}[0-9]{9}"
                          title="Please enter 10 digits mobile number"
                          maxLength={10}
                          onChange={handlePhoneNumberOnChange}
                          value={phoneNumber}
                        />
                         {errors.phoneNumber && (
                          <div className="invalid-feedback">{errors.phoneNumber}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="from">From</label>
                        <input
                          type="text"
                          id="from"
                          name="from"
                          className={`form-control ${errors.from ? "is-invalid" : ""}`}
                          placeholder="Company / From"
                          required
                          onChange={handleFromOnChange}
                          value={from}
                        />
                         {errors.from && (
                          <div className="invalid-feedback">{errors.from}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <textarea
                          name="comments"
                          id="comments"
                          className={`form-control ${errors.remarks ? "is-invalid" : ""}`}
                          rows="4"
                          placeholder="Let us know what you need."
                          required
                          onChange={handleRemarksOnChange}
                          value={remarks}
                        >
                           {errors.remarks && (
                          <div className="invalid-feedback">{errors.remarks}</div>
                        )}
                        </textarea>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <button type="submit"  className="theme-btn   btn btn-primary mb-2 " >
                        {btnName}</button>

                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="contact-image-number style-two bgs-cover overlay wow fadeInUp delay-0-4s animated"
                style={{
                  backgroundImage: `url(${background})`,
                  visibility: "visible",
                  animationName: "fadeInUp",
                }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+012-345-6789">+919384864411</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@elenageosystems.com">
                            sales@elenageosystems.com
                          </a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:00 - 6:00</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}







