import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import FaqAccordionItem from "./FaqAccordionItem";
import FaqAccordionItemNavIc from "./FaqAccordionItemNavIc";

function MainFaqSection({ faqsDrone, faqsNavIC }) {
  const [tab, setTab] = useState("description");
  const tabHandler = (e, value) => {
    e.preventDefault();
    setTab(value);
  };

  return (
    <>
      <section className="faq-section py-35">
        <div className="container faq">
          <h2 className="wow fadeInUp delay-0-4s pb-15">
            Frequently Asked Questions
          </h2>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <ul className="nav product-information-tab mb-30">
                <li>
                  <a
                    onClick={(e) => tabHandler(e, "description")}
                    href="#"
                    className={tab === "description" ? "active show" : ""}
                  >
                    NavIC
                  </a>
                </li>
                <li>
                  <a
                    onClick={(e) => tabHandler(e, "review")}
                    href="#"
                    className={tab === "review" ? "active show" : ""}
                  >
                    Drone
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content mb-20 col-sm-12 col-md-12 col-lg-12">
              <div
                className={`tab-pane fade ${tab === "description" ? "active show " : ""
                  }`}
                id="details"
              >
                <h3>Elena Geo Systems – NavIC</h3>

                <p>
                  <Card className="card-accordion">
                    <Card.Body>
                      <Accordion
                        id="accordionFaq"
                        className="border rounded overflow-hidden"
                      >
                        {faqsNavIC.map((faq, index) => (
                          <FaqAccordionItemNavIc
                            key={faq.id}
                            faq={faq}
                            isFirst={index === 0}
                            isLast={index === faqsNavIC.length - 1}
                          />
                        ))}
                      </Accordion>
                    </Card.Body>
                  </Card>
                </p>
              </div>
              <div
                className={`tab-pane fade ${tab === "review" ? "active show " : ""
                  }`}
                id="review"
              >
                <h3>Elena Geo Systems – Drone Navigation Unit (NDNU)</h3>

                <p>
                  <Card className="card-accordion">
                    <Card.Body>
                      <Accordion
                        id="accordionFaq"
                        className="border rounded overflow-hidden"
                      >
                        {faqsDrone.map((faq, index) => (
                          <FaqAccordionItem
                            key={faq.id}
                            faq={faq}
                            isFirst={index === 0}
                            isLast={index === faqsDrone.length - 1}
                          />
                        ))}
                      </Accordion>
                    </Card.Body>
                  </Card>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainFaqSection;
