import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import img1 from "../../assets/images/tracking/driver.jpeg";

function Applications({ applications }) {
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 6000);
  // }, []);

  return (
    <>
      <section className="application-section services-section pb-50">
        <div className="container">
          <div className="applications-title pb-25 text-center">
            <h2>Our Tracking Solutions</h2>
          </div>
          {/* {loading ? (
            <LoadingScreen />
          ) : ( */}
          <div className="row">
            {applications.map((data, index) => (
              <div className="col-xl-3 col-sm-6" key={index}>
                <div className="service-box wow fadeInUp delay-0-2s">
                  <div className="service-normal">
                    <div className="icon">
                      <LazyLoadImage
                        // placeholderSrc={
                        //   require(`../../assets/images/tracking/${data.compressedImg}`)
                        //     .default
                        // }
                        src={
                          require(`../../assets/images/tracking/${data.img}`)
                            .default
                        }
                        alt="Application Image"
                      // style={{ width: "600px" }}
                      />
                    </div>
                    <h6>{data.title}</h6>
                    <div className="applications-desc d-flex align-items-end">
                      <p>{data.description}
                      </p>
                      <p className="applications-arrow pl-2">
                        <i className="fas fa-arrow-right"></i>
                      </p>
                    </div>

                  </div>
                  <div className="service-hover bg-blue text-white">
                    <h3>{data.title}</h3>
                    <p>{data.description1}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* )} */}
        </div>
      </section>
    </>
  );
}

export default Applications;
