import React, { useEffect } from 'react';
import $ from 'jquery';
import 'magnific-popup';

function ColumnCardStyleThree({ datas }) {
    useEffect(() => {
        $('.gallery-link').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            closeBtnInside: false,
            mainClass: 'mfp-with-zoom mfp-img-mobile',
            image: {
                verticalFit: true,
                titleSrc(item) {
                    return item.el.find('figcaption').text() || item.el.attr('title');
                },
            },
            zoom: {
                enabled: true,
            },
            gallery: {
                enabled: true,
                navigateByImgClick: false,
                tCounter: '',
            },
            // disableOn() {
            //     return $(window).width() <= 1285;
            // },
        });

        return () => {
            $('.gallery-link').magnificPopup('destroy');
        };
    }, []);

    return (
        
        <article className="gallery">
            <a
                className="gallery-link"
                href={require(`../../../assets/images/office-gallery/${datas.image_src_url}`).default}
            >
                <figure className="gallery-image">
                    <img
                        height="1400"
                        src={require(`../../../assets/images/office-gallery/${datas.image_src_url}`).default}
                        alt="News"
                        width="1000"
                    />
                    <figcaption>{datas.gallery_first_line}</figcaption>
                </figure>
            </a>
        </article>
    );
}

export default ColumnCardStyleThree;
