import React from "react";
import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeOne from "../partials/Footers/FooterHomeOne";
import HeaderHomeOne from "../partials/Headers/HeaderHomeOne";
import MainEula from "./MainEula";

function EULA() {
  return (
    <>
      <div>
        <HeaderHomeOne />
        <MainEula />
        <FooterHomeOne />
        <BacktoTopCom />
      </div>
    </>
  );
}

export default EULA;
