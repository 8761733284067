import React from "react";
import FooterHomeTwo from "../../partials/Footers/FooterHomeOne/index";
import HeaderHomeOne from "../../partials/Headers/HeaderHomeOne";
import Hero from "./Hero";

import BacktoTopCom from "../BackToTopCom";


export default function Layouts({ children, pageTitle, breadcrumbs = [], background }) {
  return (
    <>
      <HeaderHomeOne />
      <Hero pageTitle={pageTitle} breadcrumbs={breadcrumbs} background={background}/>
      {children && children}
      <FooterHomeTwo />
      <BacktoTopCom />
    </>
  );
}
