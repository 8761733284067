import React from 'react'

function ImportantClients() {
    return (
        <section className='mb-50'>
            <div className='container'>
                <h2 className='text-center py-30'>Our Key Clients</h2>
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-6">
                        <div className="about-randd wow fadeInUp delay-0-2s">
                            <div className="icon">
                                <img
                                    src={
                                        require(`../../../assets/images/client-logo/bel.png`).default
                                    }
                                    alt="Client Logo"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="about-randd wow fadeInUp delay-0-2s">
                            <div className="icon">
                                <img
                                    src={
                                        require(`../../../assets/images/client-logo/l&t.png`).default
                                    }
                                    alt="Client Logo"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="about-randd wow fadeInUp delay-0-2s">
                            <div className="icon">
                                <img className='imp-img3'
                                    src={
                                        require(`../../../assets/images/client-logo/gsl.png`).default
                                    }
                                    alt="Client Logo"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="about-randd wow fadeInUp delay-0-2s">
                            <div className="icon">
                                <img
                                    src={
                                        require(`../../../assets/images/client-logo/indian-army.png`).default
                                    }
                                    alt="Client Logo"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ImportantClients