import React from "react";
// import { Link } from "react-router-dom";
// import IconChip from "../../assets/icons/chip.png";
// import background from "../../assets/images/background/progress.png";
// import SliderCom from "../helpers/SliderCom";

// import IconMDS from "../../assets/icons/mds.png";
// import IconTracking from "../../assets/icons/tracking.png";

export default function WorkProcessSection() {
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   arrows: false,
  //   speed: 1000,
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 4,
  //       },
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 575,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //   ],
  // };
  return (
    <>
      {/* <div className="mb-180 text-center">
        <h2 >R & D collaboration</h2>

      </div> */}
      <section className='mb-50'>
            <div className='container'>
                <h2 className='text-center py-20'>R & D collaboration</h2>
                <div className="row align-items-center">
                    <div className="col-lg-2 col-md-6">
                        <div className="about-randd wow fadeInUp delay-0-2s">
                            <div className="icon">
                                <img
                                    src={
                                      require(`../../assets/images/client-logo/rit.png`).default
                                    }
                                    alt="Client Logo"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <div className="about-randd wow fadeInUp delay-0-2s">
                            <div className="icon">
                                <img
                                    src={
                                      require(`../../assets/images/client-logo/svnit.png`).default
                                    }
                                    alt="Client Logo"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <div className="about-randd wow fadeInUp delay-0-2s">
                            <div className="icon">
                                <img className='imp-img3'
                                    src={
                                      require(`../../assets/images/client-logo/SEC.png`).default
                                    }
                                    alt="Client Logo"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <div className="about-randd wow fadeInUp delay-0-2s">
                            <div className="icon">
                                <img
                                    src={
                                      require(`../../assets/images/client-logo/gsl.png`).default
                                    }
                                    alt="Client Logo"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <div className="about-randd wow fadeInUp delay-0-2s">
                            <div className="icon">
                                <img
                                    src={
                                      require(`../../assets/images/client-logo/iit.png`).default
                                    }
                                    alt="Client Logo"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <div className="about-randd wow fadeInUp delay-0-2s">
                            <div className="icon">
                                <img
                                    src={
                                      require(`../../assets/images/client-logo/tirupati.jpeg`).default
                                    }
                                    alt="Client Logo"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
      
      {/* <section className="about-background">
        <div className="container">
          <div className="logo-carousel-wrap style-two bg-white py-100 px-25 br-5">
            <SliderCom settings={settings}>
             
              <div className="logo-item">
                <Link >
                  <img
                    src={
                      require(`../../assets/images/client-logo/rit.png`).default
                    }
                    alt="Client Logo"
                  />
                </Link>
              </div>
              <div className="logo-item">
                <Link >
                  <img
                    src={
                      require(`../../assets/images/client-logo/svnit.png`).default
                    }
                    alt="Client Logo"
                    height={130}
                    width={140}
                  />
                </Link>
              </div>
              <div className="logo-item">
                <Link >
                  <img
                    src={
                      require(`../../assets/images/client-logo/SEC.png`).default
                    }
                    alt="Client Logo"
                    height={140}
                    width={150}
                  />
                </Link>
              </div>
              <div className="logo-item">
                <Link >
                  <img
                    src={
                      require(`../../assets/images/client-logo/gsl.png`).default
                    }
                    alt="Client Logo"
                    height={140}
                    width={145}
                  />
                </Link>
              </div>
              <div className="logo-item">
                <Link >
                  <img
                    src={
                      require(`../../assets/images/client-logo/iit.png`).default
                    }
                    alt="Client Logo"
                    height={140}
                    width={145}
                  />
                </Link>
              </div>
              
            </SliderCom>
          </div>
          
        </div>
      </section> */}

    </>

  );
}
