import { Link } from "react-router-dom";

function Navigation({ className }) {
  return (
    <>
      <div className={`restly-header-main-menu ${className || ""}`}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="#">
              Products <i className="fa fa-angle-down" />
            </Link>
            <div></div>
            <ul className="sub-menu">
              <li>
                <Link to="/multi/0">Multi GNSS Processor</Link>
              </li>
              <li>
                <Link to="/module/1">Modules</Link>
              </li>
              <li>
                <Link to="/antenna/4">GNSS Antenna</Link>
              </li>

              <li>
                <Link to="/receiver/2">GNSS Receivers</Link>
              </li>
              <li>
                <Link to="/drone/3">Drone Navigation Unit</Link>
              </li>
              <li>
                <Link to="/clock/5">NavIC Clock</Link>
              </li>
              <li>
                <Link to="/map/6">Map Display Systems</Link>
              </li>
              <li>
                <Link to="/tracking/7">Tracking Devices</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/tracking-page">Tracking Systems</Link>
          </li>
          <li>
            <Link to="/news">News</Link>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          {/* <li>
            <Link to="/events">Events</Link>
          </li> */}
          <li>
            <Link to="/downloads">Downloads</Link>
          </li>
          <li>
            <Link to="#">
              Go To <i className="fa fa-angle-down" />
            </Link>
            <ul className="go-to sub-menu">
              <li>
                <Link
                  to="http://13.234.205.213/ivts/#/page/signin"
                  target="_blank"
                >
                  IVTS
                </Link>
              </li>
              <li>
                <Link to="https://sbms.elenageosys.com/login" target="_blank">
                  SBMS
                </Link>
              </li>
              {/* <li>
                <Link
                  to="https://eim.elenageosys.com/admin/login/?next=/admin/"
                  target="_blank"
                >
                  Staff Login
                </Link>
              </li> */}
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navigation;
