import React from "react";

function MainEula() {
  return (
    <section className="eula-section pt-35 pb-45">
      <div className="privacy-policy container">
        <h3 className="text-center wow fadeInUp delay-0-4s mb-20">
          END USER LICENSE AGREEMENT (EULA) UPDATED DATE: AUGUST 2022
        </h3>

        <div className="privacy-content">
          <div className="card">
            <div className="card-body">
              {/* SCOPE */}
              <div className="hero-content-five">
                <div className="section-title mt-20">
                  <h5>SCOPE</h5>
                </div>
                <p>
                  This document ("terms of use") is an agreement Elena Geo
                  Systems private limited software license agreement Important
                  legal notice to all users:
                  <br />
                  Carefully read the following legal agreement before you start
                  using the software.
                  <br />
                  This end user license agreement ("agreement") is a legal
                  agreement between you (either an individual or, if purchased
                  or otherwise acquired by or for an entity, an entity who uses
                  the software (as defined herein below) of the company
                  (hereinafter referred to as the "user/you/your") and Elena Geo
                  Systems private limited (herein after referred to as
                  "Elena/company/we/us/our" which expression shall, unless the
                  context requires otherwise, mean and include its successors
                  and permitted assigns).
                  <br /> By accepting to this agreement or by entering
                  corresponding symbol(s) you consent to be bound by the terms
                  and conditions of this agreement. Such action is a symbol of
                  your signature, and you are consenting that you have read and
                  understood this agreement and agree to be bound by the
                  agreement. You agree that you are becoming a party to this
                  agreement and agree that this agreement is enforceable like
                  any written negotiated agreement signed by you. If you do not
                  agree with the terms and conditions of this agreement, do not
                  continue the installation process and delete or destroy all
                  copies of the software in your possession.
                  <br />
                  In the event a license contract or similar document is
                  accompanied with the software, terms of the use of the
                  software as detailed in such document, this end user license
                  agreement shall be read along with such similar document.
                  <br />
                  After clicking the accept button in the agreement window or
                  after entering corresponding symbol(s) you have the right to
                  use the software in accordance with the terms and conditions
                  of this agreement.
                  <br />
                  The company reserves the right, at its sole discretion, to
                  change, modify, add or remove portions of terms of this
                  agreement, at any time.
                  <br />
                  It is your responsibility to check the terms of this agreement
                  periodically for changes. Every access by you of the platform
                  will mean that you have agreed to accept and adhere to the
                  terms of this agreement as amended and applicable at such
                  time.
                  <br />
                  The company retains the right to deny access to the software
                  to anyone whom it believes has violated any of the terms of
                  this agreement or for any reason whatsoever at its sole
                  discretion. . Elena and the User are hereinafter collectively
                  referred to as the "Parties" and individually as "Party".
                </p>
              </div>

              {/* Definitions
               */}
              <div className="hero-content-five">
                <div className="section-title mt-20">
                  <h6>Definitions</h6>
                </div>
                <p>
                  "Computer(s)" means combination of hardware(s), including
                  personal computers, laptops, workstations, personal digital
                  assistants, 'smartphones', hand-held devices, or other
                  electronic devices, and operating system(s) (including system
                  virtual machines) for which the Software is designed and will
                  be installed and/or used.
                </p>
                <ul className="list-style-three mt-0">
                  <li>
                    "User" means an individual(s) installing or using the
                    Software on his or her behalf or who is legally using a copy
                    of the Software; or, if the Software is being downloaded or
                    installed on behalf of an organization, such as an employer.
                  </li>
                  <li>
                    "Partner(s)" means organizations or individual(s), who
                    distributes the software based on an agreement and license
                    with the vendor.
                  </li>
                  <li>
                    "Update(s)" means all upgrades, revisions, patches,
                    enhancements, fixes, modifications, copies, additions or
                    maintenance packs and so forth.
                  </li>
                  <li>
                    "User Manual" means user manual, administrator guide,
                    reference book and related explanatory or other materials
                    and this is updated when necessary.
                  </li>
                  <li>
                    "License" shall have the meaning ascribed to it in clause
                    2.1 below
                  </li>
                  <li>
                    "License Key" is a unique set of characters, used for
                    activation the Software
                  </li>
                  <li>
                    "Web-Portal" means services provided by Elena and used for
                    management of the installed Software and granted Licenses,
                    as well as to obtain and store information collected from
                    the Software and for contacting technical support.
                  </li>
                </ul>
              </div>

              {/* GRANT OF LICENSE
               */}
              <div className="hero-content-five">
                <div className="section-title mt-20">
                  <h5>GRANT OF LICENSE</h5>
                </div>

                <ul className="list-style-three mt-0">
                  <li>
                    The given license grants permission to use the software on a
                    specified number of Computers to assist in protecting them,
                    from threats described in the User Manual, according to all
                    technical requirements specifications.
                  </li>
                  <li>
                    You have the right to use the Software for protection of the
                    number of Computer(s) as mentioned in Your License.
                  </li>
                  <li>
                    You lose the right to use the Software or when your license
                    expires or is terminated for any other reason according to
                    the legislation in force in the country of your principal
                    residence or in the country where you are using the
                    Software.
                  </li>
                  <li>
                    From the time of the software activation, you have the right
                    to receive the following services from Elena Geo Systems or
                    its partners for the period specified in the License.
                  </li>
                  <li>
                    Updates of the software via the Internet when and as the
                    company publishes them on its website or through other
                    online services.
                  </li>
                  <li>
                    Any updates that you may receive become part of the Software
                    and the terms and conditions of this Agreement apply to
                    them.
                  </li>
                  <li>Technical support via the internet and telephone.</li>
                  <li>
                    Access to information and auxiliary resources of the
                    Company.
                  </li>
                  <li>
                    Elena reserves the right, in its sole discretion, to
                    terminate the access to the Software or any portion thereof
                    at any time, without notice, for general maintenance or any
                    reason what so ever.
                  </li>
                  <li>
                    The Company's services offered to the User conditioned on
                    acceptance without modification of all the terms, conditions
                    and notices contained in this Agreement.
                  </li>
                  <li>
                    It is clarified, for the removal of doubts that the usage of
                    the Software by the user constitutes an acknowledgement and
                    acceptance by the User of this Agreement. If the user does
                    not agree with any part of such terms, conditions and
                    notices, the User must not use the Software.
                  </li>
                  <li>
                    The User agrees that they shall not distribute exchange,
                    modify, sell or transmit anything from the Software,
                    including but not limited to any text, images, audio and
                    video, for any business, commercial or public purpose.
                  </li>
                </ul>
              </div>

              {/* ACTIVATION AND TERM
               */}
              <div className="hero-content-five">
                <div className="section-title mt-20">
                  <h5>ACTIVATION AND TERM</h5>
                </div>

                <ul className="list-style-three mt-0">
                  <li>
                    This agreement will become valid and binding when the user
                    has acknowledged and accepted all the terms and conditions
                    herein.
                  </li>
                  <li>
                    The Company will require the User to indicate his/her
                    agreement by selecting a particular checkbox and clicking a
                    specific button during the process of registering for the
                    use of the software and some of its components provided by
                    third parties.
                  </li>
                  <li>
                    If you modify your computer or make changes to other
                    vendors' software installed on it, you may be required by
                    the company to repeat activation of the Software. The
                    company reserves the right to use any means and verification
                    procedures to verify the validity of the License and
                    legality of a copy of the Software installed and used on
                    Your Computer.
                  </li>
                  <li>
                    You have the right to use a trial version of the Software as
                    provided above without any charge for the single applicable
                    evaluation period (30 days) from the time of the Software
                    activation. If the company sets another duration for the
                    single relevant evaluation period, they will inform you of
                    such modified term.
                  </li>
                  <li>
                    You have the right to use a trial version of the Software as
                    provided above without any charge for the single applicable
                    evaluation period (30 days) from the time of the Software
                    activation. If the company sets another duration for the
                    single relevant evaluation period, they will inform you of
                    such modified term.
                  </li>
                  <li>
                    Software functionality depends on the type of license used.
                  </li>
                  <li>
                    To check the legitimacy of the Software used the Company
                    reserves the right to use means to verify that you have a
                    licensed copy of the Software. The Software can transmit
                    license information needed to confirm the legitimacy of the
                    Software to the Company. If it cannot perform the check for
                    a specified period, the Software will work with limited
                    functionality.
                  </li>
                  <li>
                    You agree that in using the Software and in using any report
                    or information derived as a result of using this Software,
                    You will comply with all applicable international, national,
                    state, regional and local laws and regulations, including,
                    without limitation, privacy, copyright, export control and
                    obscenity law.
                  </li>
                  <li>
                    Except as otherwise explicitly provided herein, you may not
                    transfer or assign any of the rights granted to you under
                    this Agreement or any of Your obligations pursuant hereto.
                  </li>
                </ul>
              </div>

              {/* TERMINATION */}
              <div className="hero-content-five">
                <div className="section-title mt-20">
                  <h5>TERMINATION</h5>
                </div>
                <span className="sub-title">
                  The Parties agree and acknowledge that:
                </span>
                <p>
                  The Company may terminate this Agreement at the option of the
                  Company without notice in the event of a breach of the terms
                  of this Agreement by the User. The User may terminate this
                  Agreement with a prior notice of 30 (Thirty) Days to the
                  Company.
                </p>
                <div className="section-title mt-20">
                  <h6>Essential Cookies:</h6>
                  <p>
                    The Parties agree and acknowledge that, upon termination of
                    this Agreement for any reason, the User understands and
                    accepts that the Company will have no further obligation to
                    provide access to the Software and all licenses and other
                    rights granted to the User under this Agreement shall cease
                    immediately.
                  </p>
                </div>
              </div>

              {/* TECHNICAL SUPPORT

             */}
              <div className="hero-content-five">
                <div className="section-title mt-20">
                  <h5>TECHNICAL SUPPORT</h5>
                </div>
                <p>
                  The technical support described in above of this Agreement is
                  provided to you when the latest Update of the Software is
                  installed. Technical support service User's data, specified on
                  Web-Portal, can be used by support specialists only when
                  processing User requests.
                </p>
              </div>

              {/* INFORMATION COLLECTION

               */}
              <div className="hero-content-five">
                <div className="section-title mt-20">
                  <h5>INFORMATION COLLECTION</h5>
                </div>
                <p>
                  When activating the Software by the activation code, to
                  collect statistical information on the distribution and use of
                  Elena’s products, you agree to provide the following
                  information during the use of the Software automatically:
                  <br />
                  The version of the installed Software, including the updates,
                  installation ID and information about current License,
                  <br />
                  The operating system version, Identifiers of the Software
                  components that are active at the time of information
                  provision.
                  <br />
                  Elena has the right to withdraw linking permissions at any
                  time and without notice..
                  <br />
                  To improve (i) security awareness about new threats and their
                  sources and (ii) Your security protection level, Elena, with
                  Your consent may collect information regarding threats,
                  security information and allied Computer environmental
                  information for Elena Cloud services. You may activate or
                  deactivate the Elena Cloud services any time in the Software
                  options page. You further acknowledge and agree that any
                  information voluntarily provided by the company to Elena, can
                  be used to track and publish reports on security risk trends
                  in the company's sole and exclusive discretion.
                </p>
              </div>

              {/* LIMITATIONS
               */}
              <div className="hero-content-five">
                <div className="section-title mt-20">
                  <h5>LIMITATIONS</h5>
                </div>
                <p>
                  You shall not emulate, clone, exploit, rent, lend, lease,
                  sell, modify, decompile, or reverse engineer the Software or
                  disassemble or create derivative works based on the Software
                  or any portion thereof with the sole exception of a
                  non-waivable right granted to You by applicable legislation.
                  <br />
                  You shall not otherwise reduce any part of the Software to
                  human readable form or transfer the licensed Software, or any
                  subset of the licensed Software, nor permit any third party to
                  do so, except to the extent applicable law expressly prohibits
                  the foregoing restriction. Neither Software's binary code nor
                  source may be used or reverse engineered to recreate the
                  program algorithm, which is proprietary. All rights not
                  expressly granted herein are reserved by Elena and its
                  suppliers, as applicable.
                  <br />
                  Any such unauthorized use of the Software shall result in
                  immediate and automatic termination of this Agreement and the
                  License granted hereunder and may result in criminal and civil
                  prosecution against you.
                  <br />
                  You shall not transfer the rights to use the Software to any
                  third party.
                  <br />
                  You shall not provide the activation code and license key to
                  third parties or allow third parties access to the activation
                  code, and License Key deemed confidential data of Elena You
                  shall not rent, lease or lend the Software to any third party.
                  If you breach any of the terms and conditions of this
                  Agreement, the company will block your license key.
                </p>
                <div className="sub-title">
                  The User agrees and acknowledges to the Company that it shall:
                </div>
                <div className="not-sub-title">
                  <span className="sub-title">
                    <u>Not : </u>
                  </span>
                  market the Software whether directly or indirectly to be any
                  person on such terms acceptable to the Elena
                  <br />
                  <span className="sub-title">
                    <u>Not : </u>
                  </span>
                  to combine or integrate the Software with any other software
                  or technologies not approved by Elena or provided by the
                  Elena, or modify, further develop or create any derivative
                  product based on the preceding. Not : Violate any local,
                  state, national or international law while accessing the
                  Software;
                  <br />
                  <span className="sub-title">
                    <u>Not : </u>
                  </span>
                  Impersonate any person or entity, or otherwise misrepresent
                  the User's affiliation with a person or entity. <br />
                  <span className="sub-title">
                    <u>Not : </u>
                  </span>
                  Use the Software or any other information received from the
                  Company or any of its affiliates for any other purpose other
                  than in the manner as permitted under this Agreement.
                  <br />
                  <span className="sub-title">
                    <u>Not : </u>
                  </span>
                  Make any false or inaccurate representations to any person
                  concerning the Software.
                  <br />
                  <span className="sub-title">
                    <u>Not : </u>
                  </span>
                  Translate any portion of the Software into any other format or
                  language;
                  <br />
                  <span className="sub-title">
                    <u>Not : </u>
                  </span>
                  To remove any copyright notices, trademark notices or any
                  private proprietary labels from the Software.
                  <br />
                  <span className="sub-title">
                    <u>Not : </u>
                  </span>
                  Reproduce the trademarks of the Company about the Software or
                  the name of the Company or any design or logo connected
                  therewith or any part thereof.
                  <br />
                  <span className="sub-title">
                    <u>Not : </u>
                  </span>
                  Publish, cause or permit to publish, any advertising referring
                  in any way to the Company or the
                  <br />
                </div>
              </div>

              {/* SOFTWARE
               */}
              <div className="hero-content-five">
                <div className="section-title mt-20">
                  <h5>SOFTWARE</h5>
                  <h5>LIMITED WARRANTY AND DISCLAIMER</h5>
                </div>
                <p>
                  Elena guarantees that the Software will substantially perform
                  according to the specifications and descriptions outlined in
                  the User Manual provided however that such limited warranty
                  shall not apply to the following:
                  <br />
                  Your Computer's deficiencies and related infringement for
                  which Elena’s expressly disclaims any warranty responsibility:
                  <br />
                  Malfunctions, defects, or failures resulting from misuse;
                  abuse; accident; neglect; improper installation, operation or
                  maintenance; theft; vandalism; acts of God; acts of terrorism;
                  power failures or surges; casualty; alteration, non-permitted
                  modification, or repairs by any party other than Elena’s or
                  any other third parties' or Your actions or causes beyond
                  Elena’s reasonable control;
                  <br />
                  Any defect not made known by you to Elena as soon as possible
                  after it first appears; and Incompatibility caused by hardware
                  and software components installed on your computer.
                  <br />
                  You acknowledge, accept and agree that no Software is error
                  free and you are advised to back-up the Computer, with
                  frequency and reliability suitable for you.
                  <br />
                  Elena does not provide any guarantee that the software will
                  work correctly in case of violations of the terms described in
                  this agreement.
                  <br />
                  Elena does not guarantee the correct working of the Software
                  if you do not regularly download updates specified above.
                  <br />
                  Elena does not guarantee protection from the threats after the
                  expiration of the license period or after termination of the
                  license for any reason. You acknowledge that by default
                  Elena’s recommended settings are applied to the Software and
                  that it is your sole responsibility to configure the Software
                  to satisfy your requirements.
                  <br />
                  The Parties agree and acknowledge that the submission of any
                  information through the Software and the download,
                  installation and use of any additional software is at the
                  discretion of the User at his/her discretion and risk.
                  <br />
                  The User shall be solely responsible for any damage to the
                  computer system or loss of data that may result from such
                  activities or reliance upon the Software. The Parties agree
                  and acknowledge that Elena is not the provider of any third
                  party software, and Elena makes no warranties concerning all
                  third-party software, third-party intellectual property rights
                  and third-party offerings.
                </p>
                <p>
                  THE SOFTWARE IS PROVIDED "AS IS", AND ELENA MAKES NO
                  REPRESENTATION AND GIVES NO WARRANTY AS TO ITS USE OR
                  PERFORMANCE. EXCEPT FOR ANY WARRANTY, CONDITION,
                  REPRESENTATION OR TERM, THE EXTENT TO WHICH CANNOT BE EXCLUDED
                  OR LIMITED BY APPLICABLE LAW, ELENA AND ITS PARTNERS MAKE NO
                  WARRANTY, CONDITION, REPRESENTATION, OR TERM (EXPRESSED OR
                  IMPLIED, WHETHER BY STATUTE, COMMON LAW, CUSTOM, USAGE OR
                  OTHERWISE) AS TO ANY MATTER INCLUDING, WITHOUT LIMITATION,
                  NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY,
                  SATISFACTORY QUALITY, INTEGRATION, OR APPLICABILITY FOR A
                  PARTICULAR PURPOSE. YOU ASSUME ALL FAULTS, AND THE ENTIRE RISK
                  AS TO PERFORMANCE AND RESPONSIBILITY FOR SELECTING THE
                  SOFTWARE TO ACHIEVE YOUR INTENDED RESULTS, AND FOR THE
                  INSTALLATION OF, USE OF, AND RESULTS OBTAINED FROM THE
                  SOFTWARE. WITHOUT LIMITING THE FOREGOING PROVISIONS, ELENA
                  MAKES NO REPRESENTATION AND GIVES NO WARRANTY THAT THE
                  SOFTWARE WILL BE ERROR-FREE OR FREE FROM INTERRUPTIONS OR
                  OTHER FAILURES OR THAT THE SOFTWARE WILL MEET ANY OR ALL YOUR
                  REQUIREMENTS WHETHER OR NOT DISCLOSED TO ELENA <br />
                  If you have questions or concerns regarding this policy, or
                  would like to update information we have about you or your
                  preferences, please contact us
                </p>
                <div className="contact-details">
                  <span className="sub-title">Email : </span>
                  <a href="mailto:info@elenageosystems.com ">info@elenageosystems.com </a><br />
                  <span className="sub-title">Phone : </span>
                  <a href="callto:080-23554555">080-23554555</a> | <a href="callto:+91938486442 ">9384864422 </a><br />
                  <span className="sub-title">Fax No : </span>
                  +91 80 23554411 <br />
                  <div className="section-title mt-20">
                    <h6>Corporate HQ</h6>
                    <p>
                      No 62/1, 1st Cross, 2nd Main, Ganganagar, Bangalore,
                      Karnataka, India, 560032
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainEula;
