import React from "react";

export default function AboutSection({ className }) {
  return (
    <section className={`about-three pb-8 rpy-100 ${className || ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="mission-vission-image">
              <div className="about-three-image rmb-60 mr-10 rmr-0 wow fadeInLeft delay-0-2s">
                <img
                  src={require(`../../assets/images/about/mission.jpg`).default}
                  alt="About"
                />
                <img
                  src={require(`../../assets/images/about/vission.png`).default}
                  alt="About"
                />
              </div>
            </div>

          </div>
          <div className="col-lg-6">
            <div className="about-content pl-70 rpl-0 wow fadeInRight delay-0-2s">
              <div className="section-title mb-35">
                <span className="sub-title"></span>
                <h2>Mission and Vision</h2>
              </div>
              <h5 className="">Mission</h5>
              <p className="about-mission">
                We intend to achieve our vision by having an end to the presence in the
                Navigation domain to support client world over. Inspired by the
                spirit of scientific research and design, we strive towards
                building high-value, reliable and world class NavIC based
                products and solutions using our own indigenously developed
                processes and proprietary technologies.
              </p>
              <ul className="list-style-three mt-15">
                <li>Empowering Navigation</li>
                <li>Innovation and Excellence</li>
                <li>Global Impact</li>
                <li>Transforming Industries</li>
              </ul>
              <h5 className="pt-3">Vision</h5>
              <p className="about-mission">
              Our solutions deliver value and innovation. This ensures continued growth and success to our customers and stakeholders alike. Together we look forward towards shaping the future of a NavIC connected world. To connect people, processes and technology via NavIC based applications with a concentrated focus on quality, R&D and customer satisfaction.
              </p>
              {/* <a href="/about-one" className="theme-btn style-three mt-25">
                View details
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
