import React from "react";

function MainPrivacyPolicy() {
  return (
    <>
      <div>
        <section className="privacy-policy-section pt-35 pb-45">
          <div className="privacy-policy container">
            <h2 className="text-center wow fadeInUp delay-0-4s pb-20">
              Privacy Policy
            </h2>

            <div className="privacy-content">
              <div className="card">
                <div className="card-body">
                  <div className="card-content">
                    <p>
                      Elena Geo Systems is a private limited company
                      incorporated under the Companies Act, 1956, hereinafter
                      shall be referred to as "Company", "Elena","We","Us","Our"
                      and terms of similar meaning. This Privacy Policy provides
                      information about data we collect, use, and share, and our
                      commitment to using the personal data.
                      <br />
                      Elena cares about privacy, security, and online safety of
                      our users. Our products and services from the risks of
                      theft, disruption, and unauthorized access to their online
                      information and activities.
                      <br />
                      This privacy policy and disclaimer, as amended from time
                      to time ("Privacy Policy/ Privacy Policy & Disclaimer")
                      forms part and parcel of the End User License Agreement
                      ("Terms") and is included herein by this reference. The
                      capitalized terms that have been used herein but are not
                      specifically defined shall have the same meaning as
                      attributed to such term in the Terms. In the event of any
                      inconsistency between the Terms and this Privacy Policy &
                      Disclaimer, the Privacy Policy & Disclaimer will supersede
                      solely in respect of the content contained herein and not
                      in respect of any other clause, term or provision
                      contained in the Terms.
                      <br />
                      This Privacy Policy applies to personal information we
                      obtain from individuals through our websites, products,
                      services, and web-based applications (collectively, the
                      "Services") and mobile applications ("Services", "Apps")
                      By accessing the services, app and/or site, you
                      (hereinafter referred to as "you", "your ","user”,
                      “customer" as applicable) expressly consent to our
                      collection, storage, use and disclosure of the Information
                      (as defined hereunder) in accordance with the terms of
                      this Privacy Policy & Disclaimer.
                      <br />
                      When you access or use our services, you acknowledge that
                      you have read this Privacy Policy and understand its
                      contents And the use of our services and any dispute over
                      privacy is subject to this Privacy Policy and Our Terms
                      (including any applicable limitations on damages and the
                      resolution of disputes).
                      <br />
                      Elena will update this Privacy Policy or other privacy
                      policy that that we establish for individual products, at
                      any time as we deem appropriate to reflect those changes.
                      Where the changes are material, we will post them here in
                      advance and, if the changes are likely to affect you
                      personally We will also attempt to contact you directly
                      (via email). It is important that you check from time to
                      time and make sure that you have reviewed the most current
                      version of this Privacy Policy.
                      <br />
                      We rely on a wide variety of information to run our
                      business. In some instances, this information may include
                      information that could be used to identify a particular
                      individual or an organization. In this Notice, We will
                      provide multiple examples of how personal information we
                      collect may be used and why it is important.
                    </p>
                  </div>

                  <div className="hero-content-five">
                    <div className="section-title">
                      <span className="sub-title">
                        The specific kind of information collected will depend
                        on the Services used:
                      </span>
                    </div>
                    <ul className="list-style-three mt-0">
                      <li>
                        Ensure all Elena’s products and services provided
                        follows defined requirements.
                      </li>
                      <li>
                        Incorporate mechanisms, process and procedures that
                        assist in monitoring the effectiveness of the products
                        and services .
                      </li>
                      <li>Embed a programme of continual improvement.</li>
                    </ul>
                  </div>

                  {/* CONSENT */}
                  <div className="hero-content-five">
                    <div className="section-title mt-20">
                      <h5>CONSENT</h5>
                    </div>
                    <p>
                      You are not required to provide any Information unless and
                      until you choose to make a purchase or sign up for one of
                      our Services. By using the services or by providing your
                      information, you consent to the collection and use of such
                      Information in accordance with this Privacy Policy &
                      Disclaimer, including but not limited to your consent for
                      sharing your. Information as per this Privacy Policy &
                      Disclaimer. You specifically agree and consent to Us
                      collecting, storing, processing, transferring and sharing
                      Information related to You with third parties, service
                      professionals or registered vendors/users for the purposes
                      as set out in this Privacy Policy & Disclaimer.
                    </p>
                  </div>

                  {/* HOW DO WE COLLECT INFORMATION?
                   */}
                  <div className="hero-content-five">
                    <div className="section-title mt-20">
                      <h5>HOW DO WE COLLECT INFORMATION?</h5>
                    </div>
                    <p>
                      We collect information directly from you, as well as
                      automatically through your use of our Services.
                    </p>
                  </div>

                  {/* INFORMATION COLLECTED AUTOMATICALLY
                   */}
                  <div className="hero-content-five">
                    <div className="section-title mt-20">
                      <h5>INFORMATION COLLECTED AUTOMATICALLY</h5>
                    </div>
                    <p>
                      When you use or interact with our site and services, we
                      receive and store information generated by your activity,
                      like usage data, and other information automatically
                      collected from your browser or mobile device. This
                      information may include Your IP address; browser type and
                      version; preferred language; geographic location using IP
                      address or the GPS, wireless, or Bluetooth technology on
                      your device; operating system and computer platform;
                      purchase history; the full Uniform Resource Locator (URL)
                      clickstream to, through, and from our site, including date
                      and time; products you viewed or searched for; and areas
                      of our site that you visited. <br />
                      We also may log the length of time of your visit and the
                      number of times you visit and purchase or use the
                      Services. We may assign you one or more unique identifiers
                      to help keep track of your future visits. In most cases,
                      this information is generated by various tracking
                      technologies. Tracking technologies may include "cookies,"
                      "flash LSOs," "web beacons" or "web bugs," and "clear
                      GIFs". You can read about how We use cookies and other
                      tracking technologies here and also learn about the
                      choices You can make to limit their use. Please see Our
                      Cookie Notice for more information about the cookies and
                      other similar technologies that we use.
                    </p>
                  </div>

                  {/* SECURITY AND THREAT DETECTION
                   */}
                  <div className="hero-content-five">
                    <div className="section-title mt-20">
                      <h5>SECURITY AND THREAT DETECTION</h5>
                    </div>
                    <p>
                      By collecting and processing data, including Personal
                      Information, Elena can help to predict threats, and better
                      protect you,
                    </p>
                    <span className="sub-title">
                      Your devices, and your information. Elena may use your
                      information to:
                    </span>
                    <ul className="list-style-three mt-0">
                      <li>
                        Analyze data sent to/from your device(s) to isolate and
                        identify threats, vulnerabilities, suspicious activity,
                        and attacks;
                      </li>
                      <li>
                        Assess the reputation of a device or file to advise you
                        on whether access should be granted;
                      </li>
                      <li>
                        Participate in threat intelligence networks and conduct
                        research and analysis, including market and consumer
                        research and trend analysis; and
                      </li>
                      <li>
                        Adapt products and services to respond to new threats.
                      </li>
                      <li>
                        We use reasonable security measures to protect
                        Information from unauthorized access, maintain data
                        accuracy, and help ensure the appropriate use of
                        information.
                      </li>
                      <li>
                        When the Services are accessed using Internet Explorer
                        version [9 and above] or later, Firefox version [15, and
                        above] or later, or Safari or later, or
                      </li>
                      <li>
                        Chrome or later, or Secure Socket Layer(“SSL") such
                        technology may protect Information using both server
                        authentication and data encryption.
                      </li>
                      <li>
                        We host our Services in a secure server environment that
                        uses firewalls and other advanced technology to prevent
                        interference or access from outside intruders.
                      </li>
                    </ul>
                  </div>

                  {/* BUSINESS USES
                   */}
                  <div className="hero-content-five">
                    <div className="section-title mt-20">
                      <h5>BUSINESS USES</h5>
                    </div>
                    <p>
                      In addition, we may use personal information for other
                      business purposes, including to:
                    </p>

                    <ul className="list-style-three mt-0">
                      <li>Establish and manage Elena device accounts;</li>
                      <li>
                        Provide customer support and respond to requests,
                        questions, and comments;
                      </li>
                      <li>
                        Communicate about, and administer participation in,
                        special events, programs, surveys, offers and promotions
                      </li>
                      <li>
                        Prevent, detect, identify, investigate, respond, and
                        protect against potential or actual claims, liabilities,
                        prohibited behavior, and criminal activity
                      </li>
                      <li>
                        Comply with and enforce applicable legal requirements,
                        agreements, and policies
                      </li>
                      <li>
                        Perform other activities consistent with this privacy
                        policy.
                      </li>
                      <li>To improve our services</li>
                      <li>To enforce our terms</li>
                      <li>
                        To protect the security or integrity of the Site, Our
                        business, services and users;
                      </li>
                    </ul>

                    <span className="sub-title">
                      We may disclose Information, in good faith, wherein the
                      access, use, preservation or disclosure of such
                      information is reasonably necessary to:
                    </span>
                    <ul className="list-style-three mt-0">
                      <li>
                        Satisfy any applicable law, regulation, legal process or
                        enforceable governmental request
                      </li>
                      <li>
                        Enforce applicable Terms, including investigation of
                        potential violations thereof
                      </li>
                      <li>
                        Detect, prevent, or otherwise address fraud, security or
                        technical issues,
                      </li>
                      <li>
                        Protect against imminent harm to the rights, property or
                        safety of the Company, its users or the public as
                        required or permitted by law; and
                      </li>
                      <li>
                        Additionally, in the event of an audit, reorganization,
                        merger, sale, joint venture, assignment, transfer or
                        other disposition of all or any portion of our business,
                        assets or stock (including in connection with any
                        bankruptcy or similar proceedings)
                      </li>
                    </ul>

                    <p>
                      We may share/transfer/assign the information that we have
                      collected to the relevant third party processing as part
                      of the services. We also process certain information as an
                      integral part of our services. If you install or use one
                      of our products or services, the software will operate in
                      the background of your computer system or device
                      environment to perform specific security and privacy tasks
                      including:
                    </p>

                    <ul className="list-style-three mt-0">
                      <li>Spam protection</li>
                      <li>Virus protection</li>
                      <li>Intrusion detection, prevention, and protection;</li>
                      <li>Threat prevention and prediction;</li>
                      <li>Network defence;</li>
                      <li>Back-up and recovery activities.</li>
                      <li>Product Updating and Reporting.</li>
                    </ul>

                    <p>
                      Our products and services may also process certain data to
                      provide updates and reports. These update functions may
                      check your system to see whether files relating to the
                      Services need to be refreshed or modernized. Products and
                      services may transmit report files to Elena. These files
                      contain information, including the number of checked,
                      suspicious, infected, or unwanted files or emails, the
                      number of infections, the date and hash values of the
                      detected infections, and the number of false
                      negatives/false positives. The purpose of these reports is
                      to analyze the frequency of particular infections or the
                      prevalence of threats. Elena also uses the information to
                      adapt the product to conform to user preferences based on
                      actual use.
                    </p>
                  </div>

                  {/* WE DO NOT SHARE PERSONAL INFORMATION?
                   */}
                  <div className="hero-content-five">
                    <div className="section-title mt-20">
                      <h5>WE DO NOT SHARE PERSONAL INFORMATION?</h5>
                    </div>
                    <p>
                      We respect the importance of privacy. Other than as
                      provided in this privacy policy, we do not sell your
                      information unless we have your consent or we are required
                      by law to do so.
                    </p>
                  </div>

                  {/* INFORMATION RETENTION */}
                  <div className="hero-content-five">
                    <div className="section-title mt-20">
                      <h5>INFORMATION RETENTION</h5>
                    </div>
                    <p>
                      The time periods for which we retain your information
                      depend on the purposes for which we use it. Elena will
                      keep your information for as long as you are a registered
                      subscriber or user of our products or for as long as we
                      have another business purpose to do so and, thereafter,
                      for no longer than is required or permitted by law,
                      reasonably necessary for internal reporting and
                      reconciliation purposes, or to provide. You with feedback
                      or information you might request. The information we
                      collect may be stored and processed in servers in the
                      cloud and wherever Elena and our service providers have
                      facilities around the globe.
                    </p>
                  </div>

                  {/* COOKIE PRIVACY POLICY */}
                  <div className="hero-content-five">
                    <div className="section-title mt-20">
                      <h5>COOKIE PRIVACY POLICY</h5>
                    </div>
                    <p>
                      Our Sites and apps use cookies and similar technologies to
                      ensure that we give our visitors the best possible
                      experience by providing you personalized information,
                      remembering your marketing and product preferences, and
                      helping you obtain the right information.
                    </p>
                  </div>

                  {/* USE OF COOKIES AND SIMILAR TECHNOLOGIES
                   */}
                  <div className="hero-content-five">
                    <div className="section-title mt-20">
                      <h5>USE OF COOKIES AND SIMILAR TECHNOLOGIES</h5>
                    </div>
                    <p>
                      This Privacy Policy describes how Elena uses cookies and
                      similar technologies (such as pixel tags, web beacons,
                      clear GIFs, JavaScript, and local storage).
                      <br />
                      We will refer to these cookies and similar technologies
                      simply as "Tracking Technologies" for the rest of privacy
                      policy. We will also describe what options you may have
                      regarding Tracking Technologies. This privacy policy
                      covers the use of cookies that may be used in connection
                      with Elena Sites, Services, and Apps. In some cases, we
                      may use Tracking Technologies to collect information that
                      is personally identifiable, or that can become personally
                      identifiable if we combine it with other information. In
                      such cases, our privacy policy will apply in addition to
                      this Cookie privacy policy.
                    </p>
                  </div>

                  {/* WHAT ARE TRACKING TECHNOLOGIES?
                   */}
                  <div className="hero-content-five">
                    <div className="section-title mt-20">
                      <h5>WHAT ARE TRACKING TECHNOLOGIES?</h5>
                    </div>
                    <p>
                      Cookies are small files usually consisting of letters and
                      numbers placed on your computer, tablet, phone, or similar
                      device when you use that device to visit a website.
                      Cookies are widely used by website owners and their
                      service providers or partners to make their websites
                      operate, work more efficiently, and provide analytic
                      information. These are the different types of cookies used
                      on our sites:
                    </p>

                    {/* essential cookies */}
                    <div className="section-title mt-20">
                      <h6>Essential Cookies:</h6>
                      <p>
                        These cookies are required for the operation of our
                        Sites (for example, to enable you to log into secure
                        areas of our website). These cookies enable you to move
                        around the sites and use their features. Disabling these
                        cookies will encumber the sites performance and may make
                        the features and service unavailable.
                      </p>
                    </div>

                    {/* Analytics and Customization Cookies:
                     */}
                    <div className="section-title mt-20">
                      <h6>Analytics and Customization Cookies:</h6>
                      <p>
                        These cookies allow us to analyze activities on our
                        sites and on other sites where we post content in order
                        to improve and optimize the way our sites work. We may
                        use these types of cookies to ensure that visitors can
                        easily find the information they are looking for. One
                        way we do this is to recognize and count the number of
                        visitors and see how they move around a Site when they
                        are using it.
                      </p>
                    </div>

                    {/* Functionality Cookies:
                     */}
                    <div className="section-title mt-20">
                      <h6>Functionality Cookies:</h6>
                      <p>
                        These cookies are used to recognize you when you return
                        to our sites or app. This enables us to personalize. Our
                        content for you, greet you by name, and remember your
                        preferences (for example, Your choice of language or
                        region).
                      </p>
                    </div>

                    {/* Flash Cookies:
                     */}
                    <div className="section-title mt-20">
                      <h6>Flash Cookies:</h6>
                      <p>
                        A flash cookie is a small data file placed on a computer
                        using Adobe Flash or similar technology that may be
                        built into your computer or downloaded or installed by
                        you to your Computer. We use these technologies to
                        personalize and enhance your online experience,
                        facilitate processes, and personalize and store your
                        settings. Flash cookies help us improve our sites by
                        measuring, which areas are of greatest interest to
                        guests. They may be recognized by other sites or by our
                        marketing or business partners. Flash cookies are
                        different from browser cookies and the cookie management
                        tools provided by your browser may not remove flash
                        cookies. If you disable flash cookies or other similar
                        technologies, please be aware that you may not have
                        access to certain features and services that make Your
                        online experience more efficient and enjoyable.
                      </p>
                    </div>
                  </div>

                  {/* HOW DO WE COLLECT AND USE OTHER INFORMATION?
                   */}
                  <div className="hero-content-five">
                    <div className="section-title mt-20">
                      <h5>HOW DO WE COLLECT AND USE OTHER INFORMATION?</h5>
                    </div>
                    <p>
                      We may use Tracking Technologies for a variety of
                      purposes, including to: Help us and third parties obtain
                      information about your visits to the Sites;
                    </p>
                    <ul className="list-style-three mt-0">
                      <li>Deliver content</li>
                      <li>Remember your language and other preferences</li>
                      <li>
                        Measure how many people use our sites, and how they use
                        them
                      </li>
                      <li>To keep our sites running efficiently.</li>
                    </ul>
                    <p>
                      If you register on one of our Sites or otherwise provide
                      us with your personal information, we may associate that
                      information with other information we collect from or
                      about you, information regarding your use of our sites, or
                      information we receive about you from third parties. We
                      may use this combined data for purposes consistent with
                      our privacy policy, including for marketing.
                    </p>
                  </div>

                  {/* HOW LONG DO COOKIES STAY ON MY DEVICE?
                   */}
                  <div className="hero-content-five">
                    <div className="section-title mt-20">
                      <h5>HOW LONG DO COOKIES STAY ON MY DEVICE?</h5>
                    </div>
                    <p>
                      Some cookies will stay on your device between browsing
                      sessions they do not expire when you close your browser.
                      These cookies are called "persistent" cookies. The length
                      of time a persistent cookie stays on your device varies
                      from cookie to cookie. Others and we use persistent
                      cookies for a variety of purposes, such as to store your
                      preferences so that they are available for the next visit.
                      Other Cookies operate from the time. When you visit our
                      site to the end of that particular web browsing session.
                      These cookies expire and are automatically deleted when
                      you close your Internet browser. These cookies are called
                      "session" cookies.
                    </p>
                  </div>

                  {/* WHO PUTS COOKIES ON MY DEVICE?
                   */}
                  <div className="hero-content-five">
                    <div className="section-title mt-20">
                      <h5>WHO PUTS COOKIES ON MY DEVICE?</h5>
                    </div>
                    <p>
                      Cookies may be placed on your device by Elena as the site
                      operator. These cookies are called "first-party" cookies.
                      A party may place some cookies on your device other than
                      Elena These cookies are called "third-party" cookies.
                      <br />
                      Cookies also allow us and third parties to know when you
                      visit our sites, and to understand how you interact with
                      emails, advertisements, or other content. Through cookies,
                      aggregate and other information not identifying individual
                      users (such as your operating system, browser version, and
                      the URL You came from, including from emails and
                      advertisements) may be obtained and used to enhance your
                      experience and understand traffic patterns. This
                      technology counts the number of users who visit a
                      particular service from specific banner advertisements
                      outside the Elena’s Site or who clicked on links or
                      graphics included in email newsletters received from
                      Elena. It is also used as a tool to compile aggregated
                      statistics about how Elena sites are used, to perform
                      analytics and help us to optimize our sites and to help
                      serve you better.
                    </p>
                  </div>

                  {/* HOW DO I MANAGE COOKIES?
                   */}
                  <div className="hero-content-five">
                    <div className="section-title mt-20">
                      <h5>HOW DO I MANAGE COOKIES</h5>
                    </div>
                    <p>
                      You can decide whether or not to accept cookies. One way
                      you can do this is through your Internet browser's
                      settings. Most Internet browsers allow some control of
                      most cookies through the browser settings (Please note
                      that if you use your browser settings to block all
                      cookies, You may not be able to use the Site fully.) You
                      can find information online about how to manage cookies If
                      you have questions or concerns regarding this privacy
                      policy, or would like to update information we have about
                      you or your preferences, please contact us
                    </p>
                    <div className="contact-details">
                      <span className="sub-title">Email : </span>
                      <a href="mailto:info@elenageosystems.com ">info@elenageosystems.com </a><br />
                      <span className="sub-title">Phone : </span>
                      <a href="callto:080-23554555">080-23554555</a> | <a href="callto:+91938486442 ">9384864422 </a><br />
                      <span className="sub-title">Fax No : </span>
                      +91 80 23554411 <br />
                      <div className="section-title mt-20">
                        <h6>Corporate HQ</h6>
                        <p>
                          No 62/1, 1st Cross, 2nd Main, Ganganagar, Bangalore,
                          Karnataka, India, 560032
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default MainPrivacyPolicy;
