import React from "react";
import DownloadsSection from './DownloadsSection';
import MoreDownloads from './MoreDownloads';
// import ScanCode from './ScanCode';
import HeaderHomeOne from "../partials/Headers/HeaderHomeOne";
import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeOne from "../partials/Footers/FooterHomeOne";

export default function Downloads() {
    return (
        <>
            <HeaderHomeOne />
            <DownloadsSection />
            <MoreDownloads />
            {/* <ScanCode /> */}
            <FooterHomeOne />
            <BacktoTopCom />
        </>
    );
}
