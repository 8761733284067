import React from "react";
// import { Link } from "react-router-dom";
import background from "../../assets/images/hero/hero-connection-bg.jpg";

function Hero() {
  return (
    <section
      className="hero-section  overlay bgs-cover pt-190"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="container">
        <div className="row justify-content-center">
            <div className="hero-content text-center">
              {/* <span className="sub-title d-block wow fadeInUp delay-0-2s">
                Multi GNSS
              </span> */}
              <h1 className="wow fadeInUp delay-0-4s  hero-text-home ">
                NavIC based <br />
                Position, Navigation and Timing Systems
              </h1>
              <div className="hero-btn  wow fadeInUp delay-0-6s">
                <div className="scroll-downs">
                  <div className="mousey">
                    <div className="scroller"></div>
                  </div>
                </div>
                {/* <Link to="/contact" className="theme-btn">
                  Contact Us
                </Link> */}

              </div>
            </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
