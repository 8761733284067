import React from "react";
import { Link } from "react-router-dom";
import IconChip from "../../assets/icons/chip.png";

import IconAntenna from "../../assets/icons/antenna.png";
import IconClock from "../../assets/icons/clock.png";
import IconMds from "../../assets/icons/mds.png";
import IconModule from "../../assets/icons/modules.png";
import IconNDNU from "../../assets/icons/ndnu.png";
import IconReceiver from "../../assets/icons/receivers.png";
import IconTracking from "../../assets/icons/tracking.png";
import Contact from "../Contact";

function FeatureSection() {
  return (
    <section className="featured-section bgs-cover pt-50 rpt-150 pb-50 rpb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="">
            <div className="section-title text-center mb-35">
              {/* <span className="sub-title">WHO WE ARE</span> */}
              <h2>
                Specialist in NavIC-based Multi GNSS Solutions
                <hr/>
              </h2>
              <h2 className="feature-text">
                Our Products Range
              </h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-3 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-2s">
              <div className="icon">
                <img src={IconChip} alt="Chip icon" className="icon-img " />
              </div>
              <div className="feature-content">
                <h4>Multi GNSS Processor </h4>
                <p>High-performance NavIC empowered Multi GNSS processors</p>
                <Link to="/multi/0" className="learn-more ">
                  Read More about Processor<i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-4s">
              <div className="icon">
                {/* <i className="flaticon flaticon-cloud-computing-1"></i> */}
                <img src={IconModule} alt="Chip icon" className="icon-img " />
              </div>
              <div className="feature-content">
                <h4>Multi GNSS Modules</h4>
                <p>
                  NavIC-enabled GNSS module empowers devices with accurate
                  positioning
                </p>
                <Link to="/module/1" className="learn-more">
                  Read More about Modules<i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
          <div className="feature-item wow fadeInUp delay-1-0s">
            <div className="icon">
              <img src={IconAntenna} alt="Chip icon" className="icon-img " />
            </div>
            <div className="feature-content">
              <h4>GNSS Antenna</h4>
              <p>
                Robust satellite navigation by receiving signals from multiple
                global navigation system
              </p>
              <Link to="/antenna/4" className="learn-more">
                Read More about Antenna<i className="fas fa-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
          <div className="col-lg-3 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-6s">
              <div className="icon">
                <img src={IconReceiver} alt="Chip icon" className="icon-img " />
              </div>
              <div className="feature-content">
                <h4>GNSS Receivers</h4>
                <p>
                  Enhanced navigation accuracy by utilizing signals from NavIC GNSS Receivers
                </p>
                <Link to="/receiver/2" className="learn-more">
                  Read More about Receivers<i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-8s">
              <div className="icon">
                <img src={IconNDNU} alt="Chip icon" className="icon-img " />
              </div>
              <div className="feature-content">
                <h4>Drone Navigation Unit</h4>
                <p>
                  Precise positioning and navigation using India's indigenous
                  satellite system
                </p>
                <Link to="/drone/3" className="learn-more">
                  Read More about NDNU <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        

      
        <div className="col-lg-3 col-md-6">
          <div className="feature-item wow fadeInUp delay-1-2s">
            <div className="icon">
              <img src={IconClock} alt="Chip icon" className="icon-img " />
            </div>
            <div className="feature-content">
              <h4>NavIC Clock</h4>
              <p>
                Advanced atomic clocks to ensure accurate and reliable
                navigation signals
              </p>
              <Link to="/clock/5" className="learn-more">
                Read More about Clock <i className="fas fa-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="feature-item wow fadeInUp delay-1-2s">
            <div className="icon">
              <img src={IconMds} alt="Chip icon" className="icon-img " />
            </div>
            <div className="feature-content">
              <h4>Map Display Systems</h4>
              <p>
                Geographic information, facilitating navigation, exploration
              </p>
              <Link to="/map/6" className="learn-more">
                Read More about MDS<i className="fas fa-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="feature-item wow fadeInUp delay-1-2s">
            <div className="icon">
              <img src={IconTracking} alt="Chip icon" className="icon-img " />
            </div>
            <div className="feature-content">
              <h4>Tracking Systems</h4>
              <p>
                Tracking system monitors and records the real-time movement
                and location
              </p>
              <Link to="/tracking/7" className="learn-more" component={Contact}>
                Read More about Tracking<i className="fas fa-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section >
  );
}

export default FeatureSection;
