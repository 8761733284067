import React from 'react'
import Page404 from './Page404'

function PageNotFoundIndex() {
    return (
        <>
            <Page404 />
        </>
    )
}

export default PageNotFoundIndex