import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function ThankYouSubscribe() {
    const nav = useNavigate()
    useEffect(() => {
        setTimeout(() => nav('/about'), 6000)
    })


    return (
        <>
            <div className="suscribe-thank">
                <h2 className="thank-msg">ThankYou !</h2>
                <div className="thank-text">
                    <p>Thanks for subscribing to our newsletter.</p>
                    <p>Please check your email for more details.</p>
                </div>

            </div>
        </>
    );
}
