// import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import ColumCardStyleEvents from "../helpers/Cards/ColumnCardEvents";
import DataIteration from "../helpers/DataIteration";
import { useEventCardDataContext } from "../Context/EventContext";

// import NewsBackground from "../../assets/images/news/news-bg.jpg";

function EventSection() {
  const [eventData, setEventData] = useState("");
  const { EventData } = useEventCardDataContext();
  const [showPopup, setShowPopup] = useState(false);
  const [FinalData, setFinalData] = useState({});
  const EventURL = "https://enquiry.elenageosys.com/event/";

  const handleButtonClick = () => {
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 5000);
  };
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await fetch(EventURL);

        if (!isMounted) {
          return; // Component is unmounted, do not update the state
        }

        if (!response.ok) {
          throw new Error("Error fetching the Event data");
        }

        const result = await response.json();
        setEventData(result);
      } catch (error) {
        console.log("Error on fetching the event API", error);
      }
    };

    fetchData();

    return () => {
      // Cleanup function to set isMounted to false when component is unmounted
      isMounted = false;
    };
  }, [EventURL]);

  useEffect(() => {
    const dataToUse =
      EventData && EventData.length !== 0 ? EventData : eventData;
    setFinalData(dataToUse);
  }, [eventData, EventData]);

  // const EventAdmin =() => {
  //   window.open("https://enquiry.elenageosys.com/admin/");
  // }

  return (
    <>
      {/* <section
        className="news-banner   overlay bgs-cover "
        style={{ backgroundImage: `url(${NewsBackground})` }}
      >
        <h1 className="text-white px-50 pb-10">Elena's News</h1>
      </section> */}
      <section className="news-section pt-40 pb-70 rpb-70">
        <div className="container">
          <div className="section-title text-center mb-55">
            {/* <span className="sub-title">Recent Articles</span> */}
            <h2>GNSS Events </h2>
          </div>
          <div className="row justify-content-center">
            <DataIteration
              datas={FinalData}
              startLength={0}
              endLength={FinalData.length}
            >
              {({ datas }) => (
                <div key={Math.random()} className="col-lg-4 col-sm-6">
                  <ColumCardStyleEvents datas={datas} />
                </div>
              )}
            </DataIteration>
          </div>
        </div>
        <div>
          <div style={{ position: "relative" }}>
            {showPopup && (
              <div className="popup-message">
                Kindly contact{" "}
                <a href="mailto:tech@elenageosystems.com">
                  tech@elenageosystems.com
                </a>
              </div>
            )}

            <div className="event-modify-button">
              <button
                type="button"
                onClick={handleButtonClick}
                className="theme-btn"
              >
                Add Events
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EventSection;

// <Link to="https://enquiry.elenageosys.com/admin/" target="_blank" className="theme-btn">
// </Link>
