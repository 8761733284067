import React from "react";
import ColumCardStyleOne from "../helpers/Cards/ColumCardStyleOne";
import DataIteration from "../helpers/DataIteration";
// import NewsBackground from "../../assets/images/news/news-bg.jpg";

function NewsSection({ news }) {
  return (
    <>
      {/* <section
        className="news-banner   overlay bgs-cover "
        style={{ backgroundImage: `url(${NewsBackground})` }}
      >
        <h1 className="text-white px-50 pb-10">Elena's News</h1>
      </section> */}
      <section className="news-section pt-40 pb-70 rpb-70">
        <div className="container">
          <div className="section-title text-center mb-55">
            {/* <span className="sub-title">Recent Articles</span> */}
            <h2>Elena in News  </h2>
          </div>
          <div className="row justify-content-center">
            <DataIteration datas={news} startLength={0} endLength={10}>
              {({ datas }) => (
                <div key={Math.random()} className="col-lg-4 col-sm-6">
                  <ColumCardStyleOne datas={datas} />
                </div>
              )}
            </DataIteration>
          </div>
        </div>
      </section>
    </>

  );
}

export default NewsSection;
