import React from "react";
import { useParams } from "react-router-dom";

// ../../../assets/images/about/about-us-bg.jpg
import productDetails from "../../../data/products.json";

// import bg from "../../../assets/images/product/multi-gnss-chip.jpg";
import Layouts from "../../helpers/Layouts";
import MainSection from "./MainSection";

export default function ProductDetails() {

  const { id } = useParams();
  // const text = "preview-2.jpg";
  // const bg1 =
  //   require(`../../../assets/images/product/${productDetails.picture[0]}`).default;
  return (

    <Layouts  >
      <MainSection product={productDetails.products[id]} />
    </Layouts>
    // <div>{id}</div>

  );
}
