import React from 'react'
import ContactThankuPage from './ContactThankuPage'

function ContactIndex() {
  return (
    <>
      <ContactThankuPage />
    </>
  )
}

export default ContactIndex