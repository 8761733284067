import React from "react";
import AboutSection from "../../HomeThree/AboutSection";
// import TestimonialSection from "../../HomeThree/TestimonialSection";
import WorkProcessSection from "../../HomeThree/WorkProcessSection";
// import Layouts from "../../helpers/Layouts";
import GetInTouchSection from "./GetInTouchSection";
import AboutTeam from "./MainAboutSection";
import BacktoTopCom from "../../helpers/BackToTopCom";
import FooterHomeOne from "../../partials/Footers/FooterHomeOne";
import HeaderHomeOne from "../../partials/Headers/HeaderHomeOne";
// import AboutBackground from "../../../assets/images/about/about-us-bg.jpg";
import ClientsIndex from "../ImpClients/ClientsIndex";

export default function AboutOne() {
  return (
    <>
      {/* <Layouts
        pageTitle="About Us"
        breadcrumbs={[]}
        background={AboutBackground}
      > */}
      <HeaderHomeOne />
      <AboutTeam />
      <AboutSection className="pb-240" />
      <ClientsIndex/>
      <WorkProcessSection />
      {/* <TestimonialSection /> */}
      <GetInTouchSection />
      <FooterHomeOne />
      <BacktoTopCom />
      {/* </Layouts> */}
    </>
  );
}
