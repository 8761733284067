import React from "react";
// import TestimonialSection from "../../HomeThree/TestimonialSection";
// import newsBg from "../../assets/images/news/news-bg.jpg";
import Layouts from "../helpers/Layouts";

// import { blogs } from "../../data/blogs.json";

import EventSection from "./EventSections";

export default function Events() {
  return (
    <>
      <Layouts>
        <EventSection  />
      </Layouts>
    </>
  );
}
