import React from 'react'
// import DataIteration from '../helpers/DataIteration';
import ColumCardStyleThree from '../helpers/Cards/ColumCardStyleThree';

function GallerySection({ gallery }) {
  return (
   <>
   <section className="news-section pt-40 pb-70 rpb-70">
        <div className="container">
          <div className="section-title text-center mb-55">
            {/* <span className="sub-title">Recent Articles</span> */}
            <h2>Gallery  </h2>
          </div>
          <div className="row justify-content-center">
            {/* <DataIteration datas={news} startLength={0} endLength={10}>
              {({ datas }) => (
                <div key={Math.random()} className="col-lg-4 col-sm-6 mb-20">
                  <ColumCardStyleThree datas={datas} />
                </div>
                
              )}
            </DataIteration> */}
            {
              gallery.map((data)=>(
                <div  key={Math.random()}  className="col-lg-4 col-sm-6 mb-20">
                  <ColumCardStyleThree datas={data} />
                </div>
              ))
            }
          </div>
        </div>
      </section>

   </>
  )
}

export default GallerySection