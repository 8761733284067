import React from "react";

function MainTermsOfUse() {
  return (
    <>
      <section className="terms-of-use-section pt-35 pb-45">
        <div className="privacy-policy container">
          <h3 className="text-center wow fadeInUp delay-0-4s pb-20">
            TERMS OF USES UPDATED DATE: AUGUST 2022
          </h3>

          <div className="privacy-content">
            <div className="card">
              <div className="card-body">
                {/* SCOPE */}
                <div className="hero-content-five">
                  <div className="section-title mt-20">
                    <h5>SCOPE</h5>
                  </div>
                  <p>
                    This document ("Terms of Use") is an agreement between you
                    (hereinafter be referred to as "You", "Your", "user", as
                    applicable) and Elena Geo Systems (hereafter referred to as
                    “Elena", "we", "us" or "our") that describes and governs the
                    terms of use which you may use/browse the Elena products,
                    services and software, hardware (collectively referred to as
                    "Services") offered on the websites and web pages or apps
                    owned and operated by Elena (collectively, the Elena Sites
                    and Apps) including but not limited to the internet domains
                    www.elenageosystems.com, Elena IVTS app and Elena SBMS app
                    <br />
                    You acknowledge and agree that by browsing or using the
                    services offered by Elena Geo Systems, you have agreed to be
                    bound by these terms of use and any additional terms that
                    may apply
                    <br />
                    Please note that Elena Geo Systems reserves the right to
                    revise these site terms of use at any time by posting an
                    update to this page. Your continued use of the Elena Sites
                    or Services following the posting of changes to these Terms
                    of use will mean you accept those changes. If you do not
                    agree with any of the Terms of Use, you must not continue to
                    use the Site. Elena reserves the right, in its sole
                    discretion, to determine if you have violated these site
                    Terms of use, and to take any action it deems appropriate.
                    You acknowledge that Elena shall have the right to terminate
                    your access to the Elena sites or services for violations of
                    any of these rules, including repeat infringement of
                    copyrights.
                  </p>
                </div>

                {/* ACCESSING THE SITE
                 */}
                <div className="hero-content-five">
                  <div className="section-title mt-20">
                    <h5>ACCESSING THE SITE</h5>
                  </div>
                  <p>
                    It is your responsibility to ensure that you have made all
                    necessary arrangements in order to access the Site or
                    services. We shall not be responsible for any telephone
                    bills, Internet charges or any other costs incurred by you
                    in order to access the site.
                    <br />
                    You are responsible for ensuring that all persons who access
                    the site or services through your Internet connection are
                    aware of these Terms of Use and the other applicable terms
                    and conditions/policies mentioned, and comply with them.
                    <br />
                    By using the site, you represent and warrant that: You are
                    at least 18 years old, and you have the lawful authority and
                    capacity to contract and be bound by these Terms of Use
                    <br />
                    If you are accepting these Terms of Use on behalf of a
                    company, limited liability partnership or other legal
                    entity, you have the authority to bind such entity to these
                    terms and, in such event, "you " and "your" as used in these
                    terms shall refer to such entity; and You will comply with
                    all applicable laws and regulations. These terms are subject
                    to change at any time without notice. To make sure you are
                    aware of any changes, please review these terms
                    periodically. Continued use of the Site or Services after
                    any such changes shall constitute your consent to such
                    changes.
                  </p>
                </div>

                {/* USE OF SERVICES/SITE
                 */}
                <div className="hero-content-five">
                  <div className="section-title mt-20">
                    <h5>USE OF SERVICES/SITE</h5>
                  </div>
                  <p>
                    You may only access the Site or Services using authorized
                    and lawful means;
                    <br />
                    Elena shall not be liable for any failure or default to
                    provide access to the site or services on account of any
                    failure or delay by you to register with the Site or
                    services for such access or due to any other reasons
                    whatsoever,.
                    <br />
                    Any configuration or set up of the devices for access to the
                    Site and the Services shall be your sole responsibility.
                    <br />
                    The Company collects, stores, processes and uses your
                    information in accordance with Company's Privacy Policy and
                    Disclaimer available in Privacy Policy. By using the site
                    and services or by providing your personal information (as
                    defined in the Privacy Policy and Disclaimer).
                    <br />
                    You will not take any action that interferes with, degrades
                    or adversely affects the Company and/or the Services and/or
                    the Site;
                  </p>
                  <span className="sub-title">
                    You will not use the Site or services in a manner
                  </span>
                  <ul className="list-style-three mt-0">
                    <li>
                      That is prohibited by any law or regulation, or
                      facilitates the violation of any law or regulation. Or
                    </li>
                    <li>Will disrupt a third parties' similar use;</li>
                    <li>Violate or tamper with the security of the site</li>
                  </ul>
                  <p>
                    That is prohibited by any law or regulation, or facilitates
                    the violation of any law or regulation; or You will not use
                    the site or services, or any portion thereof, to transmit,
                    publish, post, upload, distribute or disseminate any
                    inappropriate, harassing, abusive, defamatory, libelous,
                    obscene, illegal or deceptive content
                    <br />
                    You will not attempt to gain unauthorized access to any
                    information connected to the site/services, including but
                    not limited to, names, addresses, phone numbers, or email
                    addresses, copying copyrighted text, through hacking, or any
                    other means, or obtain or attempt to obtain any materials or
                    information through any means not intentionally made
                    available to you.
                    <br />
                    You shall be solely responsible for procuring and
                    maintaining your network connections and telecommunications
                    links, and all problems, conditions, delays, delivery
                    failures and all other loss or damage arising from or
                    relating to Your network connections or telecommunications
                    links or caused by the internet.
                  </p>
                </div>

                {/* INTELLECTUAL PROPERTY RIGHTS
                 */}
                <div className="hero-content-five">
                  <div className="section-title mt-20">
                    <h5>INTELLECTUAL PROPERTY RIGHTS</h5>
                  </div>
                  <p>
                    All content and services on the Elena Sites and Services
                    including, but not limited to, trademarks and logos,
                    designs, text, graphics, sounds, images, software, hardware
                    and other website materials (the "Materials") are the
                    intellectual property of Elena Geo Systems and protected
                    throughout the world.
                    <br />
                    You may print reasonable copies of any page(s) from the site
                    for your own personal use and you may draw the attention of
                    other users within your organization to content posted on
                    the Site.
                    <br />
                    In the event you wish to make use of any of the content on
                    the Site other than as expressly permitted above, Please
                    contact info@elenageosystems.com for permission prior to any
                    such use.
                  </p>
                </div>

                {/* INDEMNIFICATION */}
                <div className="hero-content-five">
                  <div className="section-title mt-20">
                    <h5>INDEMNIFICATION</h5>
                  </div>
                  <p>
                    By accepting these Terms of Use and using the Sites and
                    availing the Services, you agree that you shall defend,
                    indemnify and hold Elena Geo Systems and its directors,
                    employees, shareholders, officers and other representatives
                    harmless from and against any and all claims, costs,
                    damages, losses, liabilities and expenses (including
                    attorneys' fees and costs) arising out of or in connection
                    with: Your violation or breach of these Terms of Use or any
                    applicable law or regulation;
                    <br />
                    Any loss or injury to the Company's representatives
                    resulting from or attributable to your acts or omissions
                    Misuse of the Site and/or the Service
                    <br />
                    Your violation of any rights of any third party; or any and
                    all third-party claims based upon
                  </p>

                  <ul className="list-style-three mt-0">
                    <li>
                      The content of any communications transmitted by you;
                      and/or
                    </li>
                    <li>Transactions undertaken by you.</li>
                  </ul>
                </div>

                {/* LIMITATION OF LIABILITY

                 */}
                <div className="hero-content-five">
                  <div className="section-title mt-20">
                    <h5>LIMITATION OF LIABILITY</h5>
                  </div>
                  <p>
                    To the maximum extent permitted by law, we provide the
                    Services without warranties, guarantees or representations.
                    <br />
                    We assume no responsibility and shall not be liable for any
                    loss or damage, which may affect your computer equipment or
                    other equipment/property arising out of Your use of the
                    Services or access to or browsing of the site.
                    <br />
                    Under no circumstances is Elena Geo Systems liable to you
                    for any damages whatsoever (including, without limitation,
                    incidental, reliance, or consequential, or special damages
                    whether or not foreseen, lost profits, or damages resulting
                    from lost data or business interruption) on account of your
                    use, misuse, or reliance on the information services. This
                    limitation of liability shall apply to prevent recovery of
                    direct, indirect, incidental, consequential, special,
                    exemplary, or punitive damages arising from any claim
                    relating to this Terms of Use or the subject matter hereof,
                    whether such claim is based on warranty, contract, tort
                    (including negligence), or otherwise any other legal theory
                    even if Elena has been advised of the possibility of such
                    damages.
                    <br />
                    We explicitly disclaim any and all liability for any the
                    following: errors, mistakes or inaccuracies of the content
                    displayed on the Site or services. Personal injury or
                    property damage of any nature whatsoever, resulting from our
                    Services or for any alleged or actual damages or loss of
                    valuables: The acts or omissions of our representatives
                    performing services on our behalf
                    <br />
                    Any failure or delay in the site or Services
                    <br />
                    Any loss or damage arising out of your failure to adhere to
                    your obligations under the Terms of Use.
                    <br />
                    User content or the defamatory, offensive, or illegal
                    conduct of any third party with respect to any content on
                    the site or services
                    <br />
                    You agree not to use the site or services for any commercial
                    or business purposes, and acknowledge that we have no
                    liability to you for any loss of profit, loss of business,
                    business interruption or loss of business opportunity.
                    <br />
                    You acknowledge and agree that the entire risk arising out
                    of your use if the site and availing of any services remains
                    solely with you
                  </p>
                </div>

                {/* LINKING TO THE SITE/ SERVICES
                 */}
                <div className="hero-content-five">
                  <div className="section-title mt-20">
                    <h5>LINKING TO THE SITE/ SERVICES</h5>
                  </div>
                  <p>
                    You may link to our home page, provided that you do so in a
                    way that is fair and legal and does not damage our
                    reputation or take advantage of it.
                    <br />
                    You must not establish a link in any such way that suggests
                    a form of association, approval or endorsement with or by
                    Elena Geo Systems where none exists..
                    <br />
                    You must not establish a link to the site in any website
                    that does not belong to you. The site must not be framed on
                    any other site, nor may you create a link to any part of our
                    site other than the home page..
                    <br />
                    Elena has the right to withdraw linking permissions at any
                    time and without notice..
                    <br />
                    The website in which you are linking must comply with these
                    terms of use in all respects..
                    <br />
                    Should you wish to make use of any of the content on the
                    site or the services other than set out above or report any
                    complaint of abuse or misuse, please contact:
                    info@geosystem.com
                  </p>
                </div>

                {/* DISCLAIMERS AND WARRANTIES
                 */}
                <div className="hero-content-five">
                  <div className="section-title mt-20">
                    <h5>DISCLAIMERS AND WARRANTIES</h5>
                  </div>
                  <p>
                    The Services are provided to you strictly on an "as is"
                    basis. Notwithstanding anything contained in these Terms of
                    Use, the Company does not warrant that the Site:
                  </p>
                  <ul className="list-style-three mt-0">
                    <li>
                      Will perform error-free or uninterrupted, or that the
                      Company will correct all or any errors or defects
                    </li>
                    <li>
                      Will operate in combination with Your devices, or with any
                      other hardware, software, systems or data not provided by
                      the Company,
                    </li>
                    <li>
                      Will meet your requirements, specifications or
                      expectations.
                    </li>
                  </ul>
                  <p>
                    The company further acknowledges that the company does not
                    control the transfer of data over communications facilities,
                    including the internet, and that the Site an may be subject
                    to limitations, delays, and other problems inherent in the
                    use of such communications facilities.
                  </p>
                  <p>
                    Your use of the Service includes the ability to enter into
                    agreements and/or to make transactions electronically. You
                    acknowledge that your electronic submissions on the Site
                    constitute Your agreement and intent to be bound by this
                    Terms of Use.
                  </p>
                </div>

                {/* GENERAL
                 */}
                <div className="hero-content-five">
                  <div className="section-title mt-20">
                    <h5>GENERAL</h5>
                  </div>
                  <p>
                    Use shall not be deemed a waiver of such provision nor of
                    the right to enforce such provision. Our rights under these
                    Terms of Use shall survive any discontinuance of the access
                    or use of the Site.
                    <br />
                    These Terms of Use together with the End User License
                    Agreement, Privacy Policy and Disclaimer published by us on
                    the Site/Services, shall constitute the entire agreement
                    between you and Elena concerning the Site/Services.
                    <br />
                    If any provision of these Terms of Use is held to be invalid
                    or unenforceable, such provision shall be struck and the
                    remaining provisions shall be enforced to the fullest extent
                    under law.
                    <br />
                    The terms of the End User License Agreement as available at
                    here shall mutatis mutandis apply to this Terms of Use.
                  </p>
                </div>

                {/* APPLICABLE LAW
                 */}
                <div className="hero-content-five">
                  <div className="section-title mt-20">
                    <h5>APPLICABLE LAW</h5>
                  </div>
                  <p>
                    These Terms of Use, their subject matter and any
                    noncontractual disputes or claims are governed by the Indian
                    Laws and you agree to the non-exclusive jurisdiction of the
                    courts of India.
                    <br />
                    We reserve the right to seek injunctive, or any other
                    equitable relief, in any courts of competent jurisdiction
                    should We, in our sole opinion, consider this to be
                    necessary. If you have questions or concerns regarding this
                    privacy policy, or would like to update information we have
                    about you or your preferences, please contact us
                  </p>
                  <div className="contact-details">
                    <span className="sub-title">Email : </span>
                    <a href="mailto:info@elenageosystems.com ">info@elenageosystems.com </a><br />
                    <span className="sub-title">Phone : </span>
                    <a href="callto:080-23554555">080-23554555</a> | <a href="callto:+91938486442 ">9384864422 </a><br />
                    <span className="sub-title">Fax No : </span>
                    +91 80 23554411 <br />
                    <div className="section-title mt-20">
                      <h6>Corporate HQ</h6>
                      <p>
                        No 62/1, 1st Cross, 2nd Main, Ganganagar, Bangalore,
                        Karnataka, India, 560032
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainTermsOfUse;
