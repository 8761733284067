import React from "react";
import { faqsDrone } from "../../data/faqsDrone.json";
import { faqsNavIC } from "../../data/faqsNavIC.json";
import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeOne from "../partials/Footers/FooterHomeOne";
import HeaderHomeOne from "../partials/Headers/HeaderHomeOne";
import MainFaqSection from "./MainFaqSection";

function FAQ() {
  return (
    <>
      <HeaderHomeOne />
      <MainFaqSection faqsDrone={faqsDrone} faqsNavIC={faqsNavIC} />
      <FooterHomeOne />
      <BacktoTopCom />
    </>
  );
}

export default FAQ;
