import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
// import createMarkup from "helpers/createMarkup";
// import { caretRight } from "@fortawesome/fontawesome-free-solid";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Accordion, Card } from "react-bootstrap";

// fontawesome.library.add(faCaretRight);

function FaqAccordionItemNavIc({ faq, isFirst, isLast }) {
  return (
    <Card
      className={classNames("shadow-none", {
        "rounded-bottom-0": isFirst,
        "border-bottom": !isLast,
        "rounded-0": !isLast && !isFirst,
      })}
    >
      <Accordion.Item eventKey={faq.id} className="border-0">
        <Card.Header className="p-0" id={`faqAccordionHeading${faq.id}`}>
          <Accordion.Button className="btn btn-link text-decoration-none d-block w-100 py-2 px-3 border-0 rounded-0 text-start shadow-none">
            <div className="d-flex">
              <FontAwesomeIcon
                icon={faCaretRight}
                className="accordion-icon me-3 mt-1"
                transform="shrink-2"
              />
              <span className="fw-medium font-sans-serif text-900">
                {faq.title}
              </span>
            </div>
          </Accordion.Button>
        </Card.Header>
        <Accordion.Body className="p-0">
          <Card.Body className="pt-3 pl-35">
            {/* <div className="ps-3" {faq.description} /> */}
            <div>{faq.description}</div>
            <div className="pt-5">
              <b>{faq.aspectTitle}</b>
            </div>
            <div className="aspects">
              {faq.aspects &&
                faq.aspects.map((data, index) => (
                  <ul key={index} className="my-3">
                    <li>{data.aspect}</li>
                  </ul>
                ))}
            </div>
            <div>{faq.description1}</div>
            <table className="table">
              <thead>
                {faq.tableHeading &&
                  faq.tableHeading.map((item, index) => (
                    <tr key={index}>
                      <th>{item.title1}</th>
                      <th>{item.title2}</th>
                      <th>{item.title3}</th>
                    </tr>
                  ))}
              </thead>
              <tbody>
                {faq.tableData &&
                  faq.tableData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.availability}</td>
                      <td>{item.navIC}</td>
                      <td>{item.gps}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div>{faq.description2}</div>
            <div>{faq.description3}</div>
          </Card.Body>
        </Accordion.Body>
      </Accordion.Item>
    </Card>
  );
}

// FaqAccordionItemNavIc.propTypes = {
//   isFirst: PropTypes.bool,
//   isLast: PropTypes.bool,
//   faq: PropTypes.shape({
//     id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
//     title: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//   }),
// };

export default FaqAccordionItemNavIc;
