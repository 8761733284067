import React from 'react'
import GallerySection from './GallerySection';
import Layouts from '../helpers/Layouts';
import { gallery } from '../../data/gallery.json'

function GalleryIndex() {
  return (
    <>
    <Layouts>
     <GallerySection gallery={gallery}/>
    </Layouts>

    </>
  )
}

export default GalleryIndex