import React from 'react'
import ImportantClients from './ImportantClients'

function ClientsIndex() {
  return (
   <>
   <ImportantClients/>
   </>
  )
}

export default ClientsIndex