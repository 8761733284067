import React from "react";

function ColumCardStyleOne({ datas }) {
  return (
    <div className="news-item wow fadeInUp delay-0-2s">
      <div className="image">
        <img
          src={
            require(`../../../assets/images/news/${datas.image_src_url}`)
              .default
          }
          alt="News"
        />
      </div>
      <div className="news-content">
        <ul className="post-meta-item">
          <li>
            <i className={datas.date_icon}></i>
            <a href="#" rel="bookmark">
              {/* 22 December 2021 */}
              {(datas.date_posted_on)}
            </a>
          </li>
          <li>
            <i className="fas fa-tag"></i>
            <a>{datas.news_tag}</a>
          </li>
        </ul>
        <h5>
          <a href={datas.news_url_link} target="blank" className="line-clamp-2">
            {datas.news_heading}
          </a>
        </h5>
        <p className="line-clamp-2">{datas.news_first_line}</p>
      </div>
      {/* <div className="news-author">
        <img
          src={
            require(`../../../assets/images/news/${datas.profile_photo}`)
              .default
          }
          alt="Authror"
        />
        <span className="posted-by">
          By <a href="#">{datas.username}</a>
        </span>
      </div> */}
    </div>
  );
}

export default ColumCardStyleOne;
