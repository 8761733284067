// import React from "react";
// import Background from "../../assets/images/counter/counter-bg.jpg";
// // import E1A from "../../assets/images/about/e1a.jpg";
// import CounterUp from "../helpers/CounterUp";

function AboutSection({ className }) {
  return (
    <section
      className={`about-section bg-light-black pt-80 rpt-100 pb-80 ${
        className || ""
      }`}
      id="about-section "
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-image-shape rmb-70 wow fadeInLeft delay-0-2s">
              <img
                src={require(`../../assets/images/about/e1a.png`).default}
                alt="About"
                style={{ width: "465px" }}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content text-white pr-70 rpr-0 wow fadeInRight delay-0-2s">
              <div className="section-title mb-35">
                <span className="sub-title">PNT solutions For all applications</span>
                <h2>
                  India's first NavIC based Multi GNSS processor for precise
                  applications
                </h2>
              </div>
              <p>
                High-precision NavIC processor enables accurate navigation and
                positioning using India's indigenous satellite navigation system
              </p>
              <ul className="list-style-one mt-15">
                <li>Make in India</li>
                <li>Multi-Constellation </li>
                <li>Embedded Processing</li>
                <li>High-Precision</li>
              </ul>
            </div>
          </div>
        </div>
        {/* {funFact && (
          <div
            className="fact-counter-inner br-5 px-25 pt-80 pb-30 text-white text-center"
            style={{ backgroundImage: `url(${Background})` }}
          >
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-2s">
                  <span
                    className="count-text"
                    data-speed="5000"
                    data-stop="520"
                  >
                    <CounterUp endValue={75} sectionSelect="about-section " />
                  </span>
                  <p>Projects Done</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-4s">
                  <span className="count-text plus">
                    <CounterUp endValue={45} sectionSelect="about-section " />
                  </span>
                  <p>Awards Won</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-6s">
                  <span
                    className="count-text plus"
                    data-speed="5000"
                    data-stop="12"
                  >
                    <CounterUp endValue={10} sectionSelect="about-section " />
                  </span>
                  <p>Years Experience</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-8s">
                  <span
                    className="count-text plus"
                    data-speed="5000"
                    data-stop="352"
                  >
                    <CounterUp endValue={100} sectionSelect="about-section " />
                  </span>
                  <p>Happy Clients</p>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </section>
  );
}

export default AboutSection;
