import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Feature({ feature }) {
  useEffect(() => {
    const myId = window.location.hash.slice(1);
    const elem = document.getElementById(myId);
    if (elem) {
        elem.scrollIntoView();
    }
}, []);
  return (
    <section className="customization-section pt-10">
      <div className="container pb-50">
        <div
          className={`row align-items-center ${feature.isImageLeft && "customization-content-ltr"
            }`}
        >
          <div className="col-lg-6">
            <div className="">
              <img
                src={
                  require(`../../assets/images/tracking/${feature.img1}`)
                    .default
                }
                // src={thumb2}
                alt="Live Tracking"
              />
              {/* <img
                src={
                  require(`../../assets/images/tracking/${feature.img2}`)
                    .default
                }
                alt="customization"
              /> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="customization-content  mr-100 rmr-0 pb-35 rpb-40 rpt-50 wow fadeInRight delay-0-2s">
              <div className="section-title mb-25">
                {/* <span className="sub-title">{feature.subTitle}</span> */}
                <h2>{feature.title}</h2>
              </div>
              <p>{feature.description}</p>
              <ul className="list-style-three mt-25 pb-10">
                {/* <li>top audience</li>
                <li>Web & app development</li>
                <li>software analysis</li>
                <li>Appplication design</li> */}
                {feature.listFeatures.map((_feature,i) => (
                  <li key={i}>{_feature}</li>
                ))}
              </ul>
              <Link to="/contact#contactForm" className="theme-btn mt-15">
                Discover More <i className="fas fa-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
