import React from "react";
import ThankYouSubscribe from "./ThankYouSubscribe";

export default function Thankyou() {
    return (
        <>
            <ThankYouSubscribe />
        </>
    );
}
