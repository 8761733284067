import React from "react";
import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeOne from "../partials/Footers/FooterHomeOne";
import HeaderHomeOne from "../partials/Headers/HeaderHomeOne";
import MainTermsOfUse from "./MainTermsOfUse";

function TermsOfUse() {
  return (
    <>
      <div>
        <HeaderHomeOne />
        <MainTermsOfUse />
        <FooterHomeOne />
        <BacktoTopCom />
      </div>
    </>
  );
}

export default TermsOfUse;
