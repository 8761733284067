import React from "react";
import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeOne from "../partials/Footers/FooterHomeOne";
import HeaderHomeOne from "../partials/Headers/HeaderHomeOne";
import MainPrivacyPolicy from "./MainPrivacyPolicy";

function PrivacyPolicy() {
  return (
    <>
      <div>
        <HeaderHomeOne />
        <MainPrivacyPolicy />
        <FooterHomeOne />
        <BacktoTopCom />
      </div>
    </>
  );
}

export default PrivacyPolicy;
